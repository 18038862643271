import React, { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../../util/axios/axios";
import { useAtom } from "jotai/index";
import { customerManage_mainData_atom } from "../../../../data/atom/workManage/customerManage/customerManage_atom";
import moment from "moment";
import { useGetEtcSmQ } from "../../../../api/code/useGetEtcSmQ";
import { useGetMemberNm } from "../../../../api/code/useGetMemberNm";
import { useGetEtcCmQ } from "../../../../api/code/useGetEtcCmQ";
import MembershipItemSelect from "../../../../components/select/MembershipItemSelect";

export const MemberShip = () => {
  const [mainData, setMainData] = useAtom(customerManage_mainData_atom);
  const [subData, setSubData] = useState([]);

  const [modifyList, setModifyList] = useState([]);
  const [deleteList, setDeleteList] = useState([]);

  const getMemberNm = useGetMemberNm(); // 구분(gu)가 1(골프) 일때만 사용
  const getEtcCmQ = useGetEtcCmQ(); // 구분(gu)가 2(콘도) 일때만 사용
  const getEtcSmQ = useGetEtcSmQ(); // 구분(gu)가 3(헬스) 일때만 사용

  // 보유회원권 조회
  const customerGetMemberShip = useMutation(
    data => axios.get("/api/work/customer/right/getMemberShip", { params: { custId: data } }),
    {
      onSuccess: res => {
        setSubData(
          res.data.result.map(el => ({
            ...el,
            inDate: el.inDate ? moment(el.inDate).format("YYYY-MM-DD") : "",
            deleteDate: el.deleteDate ? moment(el.deleteDate).format("YYYY-MM-DD") : "",
            pastCardNo: el.cardNo,
            pastCustId: el.custId,
            pastGu: el.gu,
            pastIlNo: el.ilNo,
          }))
        );
        setModifyList(Array.from({ length: res.data.result.length }, () => false));
        setDeleteList(Array.from({ length: res.data.result.length }, () => false));
      },
      onError: err => {},
    }
  );

  // 보유회원권 저장/수정
  const customerSaveMemberShip = useMutation(
    data => axios.post("/api/work/customer/right/saveMemberShip", data),
    {
      onSuccess: res => {
        customerGetMemberShip.mutate(mainData.seqNo);
      },
    }
  );

  // 보유회원권 삭제
  const customerDeleteMemberShip = useMutation(
    data => axios.post("/api/work/customer/right/deleteMemberShip", data),
    {
      onSuccess: res => {
        customerGetMemberShip.mutate(mainData.seqNo);
      },
    }
  );

  useEffect(() => {
    mainData.seqNo && customerGetMemberShip.mutate(mainData.seqNo);
  }, [mainData.seqNo]);

  const btn_all_delete = () => {
    if (window.confirm("완전 삭제를 하시겠습니까?")) {
      if (deleteList.every(el => el === false)) {
        alert("완전 삭제할 데이터를 선택해주세요.");
        return;
      }

      const temp = subData.filter((el, index) => deleteList[index]);

      customerDeleteMemberShip.mutate(
        temp.map(el => ({
          cardNo: el.cardNo,
          custId: el.custId,
          gu: el.gu,
          ilNo: el.ilNo,
          type: "Y",
        }))
      );
    }
  };

  const btn_delete = () => {
    if (window.confirm("삭제를 하시겠습니까?")) {
      if (deleteList.every(el => el === false)) {
        alert("삭제할 데이터를 선택해주세요.");
        return;
      }

      const temp = subData.filter((el, index) => deleteList[index]);

      customerDeleteMemberShip.mutate(
        temp.map(el => ({
          cardNo: el.cardNo,
          custId: el.custId,
          gu: el.gu,
          ilNo: el.ilNo,
          type: "",
        }))
      );
    }
  };

  const btn_add = () => {
    if (modifyList.some(el => el === true)) {
      alert("수정중인 데이터가 있습니다. 저장 후 등록해주세요.");
      return;
    }

    setSubData(prev => [
      {
        cardNo: "",
        custId: mainData.seqNo,
        gu: "",
        ilNo: 0,
        inDate: "",
        pastCardNo: "",
        pastCustId: "",
        pastGu: "",
        pastIlNo: 0,
        remark: "",
        deleteDate: "",
        deleteGubun: "N",
        deleteId: "",
      },
      ...prev,
    ]);
    setModifyList(prev => [true, ...prev]);
    setDeleteList(prev => [false, ...prev]);
  };

  const btn_register = () => {
    if (subData.some(el => el.ilNo === 0)) {
      if (window.confirm("등록을 하시겠습니까?")) {
        if (subData[0].gu === "") {
          alert("구분을 선택해주세요.");
          return;
        }

        if (subData[0].cardNo === "") {
          alert("회원권명을 선택해주세요.");
          return;
        }

        // if (subData[0].inDate === "") {
        //   alert("보유일자를 입력해주세요.");
        //   return;
        // }

        const temp = subData.filter((el, index) => modifyList[index]);

        customerSaveMemberShip.mutate(temp);
      }
    } else {
      if (window.confirm("수정을 하시겠습니까?")) {
        if (modifyList.every(el => el === false)) {
          alert("수정된 데이터가 없습니다.");
          return;
        }

        const temp = subData.filter((el, index) => modifyList[index]);

        customerSaveMemberShip.mutate(temp);
      }
    }
  };

  const btn_cancel = () => {
    if (window.confirm("수정을 취소하시겠습니까?")) {
      customerGetMemberShip.mutate(mainData.seqNo);
    }
  };

  return (
    <div className="tab2-content-container">
      <div className="d-flex align-items-center justify-content-between">
        <div className="table-tabs-sub m-0">
          보유회원권 (<span>{subData?.length ?? 0}</span>건)
        </div>
        <div className="d-flex">
          <button className="btn btn-outline-danger tab1-row1-btn" onClick={btn_all_delete}>
            완전삭제
          </button>
          <button className="btn btn-outline-danger tab1-row1-btn" onClick={btn_delete}>
            삭제
          </button>
          <button className="btn btn-outline-primary tab1-row1-btn" onClick={btn_add}>
            추가
          </button>
          <button className="btn btn-outline-primary tab1-row1-btn" onClick={btn_register}>
            저장
          </button>
          <button className="btn btn-outline-primary tab1-row1-btn" onClick={btn_cancel}>
            취소
          </button>
        </div>
      </div>
      <div className="table-container table-sticky h-443 mt-10">
        <table className="table table-bordered table-responsive my-table mt-0">
          <colgroup>
            {/*총합 100%*/}
            <col width="5%" />
            <col width="7%" />
            <col width="7%" />
            <col width="25%" />
            <col width="26%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
          </colgroup>
          <thead>
            <tr>
              <th>삭제</th>
              <th colSpan={2}>구분</th>
              <th>회원권명</th>
              <th>비고</th>
              <th>보유일자</th>
              <th>삭제자</th>
              <th>삭제일시</th>
            </tr>
          </thead>
          <tbody>
            {subData.length !== 0 ? (
              subData?.map((el, index) => (
                <tr className={`${el.deleteGubun === "Y" ? "bg-red" : ""}`}>
                  <td
                    className="cursor-pointer"
                    onClick={() => {
                      const newDeleteList = [...deleteList];
                      newDeleteList[index] = !newDeleteList[index];
                      setDeleteList(newDeleteList);
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={deleteList[index]}
                      onChange={e => {
                        const newDeleteList = [...deleteList];
                        newDeleteList[index] = e.target.checked;
                        setDeleteList(newDeleteList);
                      }}
                      disabled={el.ilNo === 0}
                    />
                  </td>
                  <td>{el.deleteGubun === "Y" ? "前" : ""}</td>
                  <td>
                    <select
                      className="form-select"
                      value={el.gu}
                      onChange={e => {
                        const newSubData = [...subData];
                        newSubData[index].gu = e.target.value;
                        newSubData[index].cardNo = "";
                        setSubData(newSubData);

                        const newModifyList = [...modifyList];
                        newModifyList[index] = true;
                        setModifyList(newModifyList);
                      }}
                      disabled={el.deleteGubun === "Y"}
                    >
                      <option value="" disabled={true} selected={true}>
                        구분 선택
                      </option>
                      <option value="1">골프</option>
                      <option value="2">콘도</option>
                      <option value="3">헬스</option>
                    </select>
                  </td>
                  <td>
                    {/*<select*/}
                    {/*  className="form-select"*/}
                    {/*  value={*/}
                    {/*    el.cardNo*/}

                    {/*    // el.gu === "1"*/}
                    {/*    //   ? getMemberNm.data?.find(el2 => el2.key === el.cardNo)?.value*/}
                    {/*    //   : el.gu === "2"*/}
                    {/*    //   ? getEtcCmQ.data?.find(el2 => el2.key === el.cardNo)?.value*/}
                    {/*    //   : el.gu === "3" &&*/}
                    {/*    //     getEtcSmQ.data?.find(el2 => el2.key === el.cardNo)?.value*/}
                    {/*  }*/}
                    {/*  onChange={e => {*/}
                    {/*    const newSubData = [...subData];*/}
                    {/*    newSubData[index].cardNo = e.target.value;*/}
                    {/*    setSubData(newSubData);*/}

                    {/*    const newModifyList = [...modifyList];*/}
                    {/*    newModifyList[index] = true;*/}
                    {/*    setModifyList(newModifyList);*/}
                    {/*  }}*/}
                    {/*  disabled={el.deleteGubun === "Y"}*/}
                    {/*>*/}
                    {/*  <option value="" disabled={true} selected={true}>*/}
                    {/*    회원권명 선택*/}
                    {/*  </option>*/}
                    {/*  {el.gu === "1"*/}
                    {/*    ? getMemberNm.data?.map(el2 => (*/}
                    {/*        <option value={el2.code}>{el2.codename}</option>*/}
                    {/*      ))*/}
                    {/*    : el.gu === "2"*/}
                    {/*    ? getEtcCmQ.data?.map(el2 => <option value={el2.key}>{el2.value}</option>)*/}
                    {/*    : el.gu === "3" &&*/}
                    {/*      getEtcSmQ.data?.map(el2 => <option value={el2.key}>{el2.value}</option>)}*/}
                    {/*</select>*/}
                    {el.gu === "1" && (
                      <MembershipItemSelect
                        gubun={el?.gu}
                        data={el?.cardNo}
                        keyName={"cardNo"}
                        onChangeEventHandler={(key, value) => {
                          const newSubData = [...subData];
                          newSubData[index].cardNo = value;
                          setSubData(newSubData);

                          const newModifyList = [...modifyList];
                          newModifyList[index] = true;
                          setModifyList(newModifyList);
                        }}
                        isDisabled={el.deleteGubun === "Y"}
                      />
                    )}

                    {el.gu === "2" && (
                      <MembershipItemSelect
                        gubun={el?.gu}
                        data={el?.cardNo}
                        keyName={"cardNo"}
                        onChangeEventHandler={(key, value) => {
                          const newSubData = [...subData];
                          newSubData[index].cardNo = value;
                          setSubData(newSubData);

                          const newModifyList = [...modifyList];
                          newModifyList[index] = true;
                          setModifyList(newModifyList);
                        }}
                      />
                    )}

                    {el.gu === "3" && (
                      <MembershipItemSelect
                        gubun={el?.gu}
                        data={el?.cardNo}
                        keyName={"cardNo"}
                        onChangeEventHandler={(key, value) => {
                          const newSubData = [...subData];
                          newSubData[index].cardNo = value;
                          setSubData(newSubData);

                          const newModifyList = [...modifyList];
                          newModifyList[index] = true;
                          setModifyList(newModifyList);
                        }}
                      />
                    )}
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      value={el.remark}
                      onChange={e => {
                        const newSubData = [...subData];
                        newSubData[index].remark = e.target.value;
                        setSubData(newSubData);

                        const newModifyList = [...modifyList];
                        newModifyList[index] = true;
                        setModifyList(newModifyList);
                      }}
                      readOnly={el.deleteGubun === "Y"}
                    />
                  </td>
                  <td>
                    <input
                      type="date"
                      className="form-control"
                      value={el.inDate}
                      onChange={e => {
                        const newSubData = [...subData];
                        newSubData[index].inDate = e.target.value;
                        setSubData(newSubData);

                        const newModifyList = [...modifyList];
                        newModifyList[index] = true;
                        setModifyList(newModifyList);
                      }}
                      max="9999-12-31"
                      readOnly={el.deleteGubun === "Y"}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      value={el.deleteId}
                      // onChange={e => {
                      //   const newSubData = [...subData];
                      //   newSubData[index].deleteId = e.target.value;
                      //   setSubData(newSubData);
                      // }}
                      readOnly={true}
                    />
                  </td>
                  <td>
                    <input
                      type="date"
                      className="form-control"
                      value={el.deleteDate}
                      // onChange={e => {
                      //   const newSubData = [...subData];
                      //   newSubData[index].deleteDate = moment(e.target.value).format("YYYY-MM-DD");
                      //   setSubData(newSubData);
                      // }}
                      readOnly={true}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8} className="text-center">
                  검색된 보유회원권이 없습니다.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
