import React, { useEffect, useState } from "react";
import { axios } from "../../../../util/axios/axios";
import { useMutation } from "@tanstack/react-query";
import { CustomerManageDetail } from "../customerManageDetail/customerManageDetail";
import { useGetSearchCondition } from "../../../../api/code/useGetSearchCondition";
import { useAtom, useSetAtom } from "jotai/index";
import {
  customerManage_isDuplicateCheck_atom,
  customerManage_mainData_atom,
  customerManage_mainDataInit_atom,
  customerManage_searchData_atom,
  customerManage_searchDataInit_atom,
  customerManage_searchedTableList_atom,
  customerManage_searchedTableListChecked_atom,
  customerManage_searchedTableListInit_atom,
  customerManage_selectedCustomer_atom,
  customerManage_tableLimit_atom,
  customerManage_tapList_atom,
  weborderList_atom,
} from "../../../../data/atom/workManage/customerManage/customerManage_atom";
import { useGetDb } from "../../../../api/code/useGetDb";
import moment from "moment";
import { useGetAsCode127Keyword } from "../../../../api/code/useGetAsCode127Keyword";
import { useGetEtccodeDw } from "../../../../api/code/useGetEtccodeDw";
import { ProcessingResultModal } from "./modal/processingResultModal";
import { CallReservationModal } from "./modal/callReservationModal";
import { SendSMSConfirmModal } from "./modal/sendSMSConfirmModal";
import { useLocation, useNavigate } from "react-router-dom";
import useRecentTabEvent from "../../../../components/layout/useRecentTabEvent";
import { SendSMSModal } from "./modal/sendSMSModal";
import { decrypt } from "../../../../util/decrypt/decrypt";
import { useGetCustGubun } from "../../../../api/code/useGetCustGubun";
import { useSidebarOnClickEvent } from "../../../../util/sidebarOnClickEvent/sidebarOnClickEvent";
import { regex_only_number } from "../../../../util/validation";
import { useSelectGetUp } from "../../../../api/code/useSelectGetUp";
import { useGetEmail } from "../../../../api/code/useGetEmail";
import { useGetPartner } from "../../../../api/code/useGetPartner";

export const CustomerManage = () => {
  const defaultURL = process.env.PUBLIC_URL;
  const navigate = useNavigate();
  const location = useLocation();
  const { addTabClosedEvent } = useRecentTabEvent();
  const { sidebarOnClickEvent } = useSidebarOnClickEvent();

  const [infinityScroll, setInfinityScroll] = useState(null);

  // 고객 검색 조건 데이터
  const [searchData, setSearchData] = useAtom(customerManage_searchData_atom);
  // 고객 검색 테이블 갯수
  const [tableLimit, setTableLimit] = useAtom(customerManage_tableLimit_atom);
  // 고객 검색 결과 리스트
  const [searchedTableList, setSearchedTableList] = useAtom(customerManage_searchedTableList_atom);
  // 고객 검색 결과 리스트 체크박스
  const [searchedTableListChecked, setSearchedTableListChecked] = useAtom(
    customerManage_searchedTableListChecked_atom
  );
  const setWebOrderList = useSetAtom(weborderList_atom);

  // 고객관리 선택된 탭 리스트
  const [tapList, setTapList] = useAtom(customerManage_tapList_atom);
  // 고객관리 현재 선택된 고객
  const [selectedCustomer, setSelectedCustomer] = useAtom(customerManage_selectedCustomer_atom);

  const [mainData, setMainData] = useAtom(customerManage_mainData_atom);

  // 신규등록 중복체크 여부
  const [isDuplicateCheck, setIsDuplicateCheck] = useAtom(customerManage_isDuplicateCheck_atom);

  // 제명(복구)처리결과 모달
  const [isProcessingResultModalOpen, setIsProcessingResultModalOpen] = useState({
    data: null,
    isOpen: false,
  });

  // 통화예약 모달
  const [isCallReservationModalOpen, setIsCallReservationModalOpen] = useState({
    data: null,
    isOpen: false,
  });

  // SMS 발송 모달
  const [isSendSMSModalOpen, setIsSendSMSModalOpen] = useState({
    data: null,
    isOpen: false,
  });

  // 문자 전송 확인 모달
  const [isSendSMSConfirmModalOpen, setIsSendSMSConfirmModalOpen] = useState({
    data: null,
    isOpen: false,
  });

  // 마지막 체크한 인덱스
  const [lastCheckedIndex, setLastCheckedIndex] = useState(-1);

  const getCustGubun = useGetCustGubun(); // 고객분류1
  const getSearchCondition = useGetSearchCondition(); // 검색조건
  const getDb = useGetDb(); // 명단출처
  const getAsCode127Keyword = useGetAsCode127Keyword();
  const getEtccodeDw = useGetEtccodeDw("MP");
  const selectGetUp = useSelectGetUp();
  const getEmail = useGetEmail();
  const getPartner = useGetPartner(); // 담당딜러

  // 통화예약알림
  const customerAlertCallBook = useMutation(() => axios.get("/api/work/customer/alertCallBook"), {
    onSuccess: res => {
      if (res.data.result.length !== 0) {
        if (
          window.confirm(
            `오늘 ${res.data.result.length}건이 통화 예약 되어 있습니다.\n\n조회하시겠습니까?`
          )
        ) {
          const newSearchData = {
            ...searchData,
            ...getSearchCondition.data.find(el => el.value === "통화 예약 업체"),
            keyword: moment().format("YYYY-MM-DD"),
            asCode: getSearchCondition.data.find(el => el.value === "통화 예약 업체").key,
            mngByMe: true,
            mngPlace: true,
            includeSms: null,
            pastOwner: null,
            radio: null,
            page: 1,
            searchType: "1",
          };

          customerSearch.mutateAsync(newSearchData).then(res => {
            if (res.data.result.list.length === 0) {
              alert("해당 검색어로 조회하신 내용은 존재하지 않습니다...!!");
            }

            setSearchedTableList(res.data.result);
            setSearchData({
              ...newSearchData,
              searchType: "1",
            });
          });
        }
      }
    },
  });

  // 고객검색
  const customerSearch = useMutation(data =>
    axios.get("/api/work/customer/search", { params: data })
  );

  // 고객 트리 검색
  const customerSearchFilter = useMutation(data =>
    axios.get("/api/work/customer/searchFilter", { params: { ...data, keyword: "" } })
  );

  // 고객 상세 검색
  const customerSearchDetail = useMutation(data =>
    axios.get("/api/work/customer/searchDetail", { params: data })
  );

  // 고객 상세 가져오기
  const customerGetDetail = useMutation(
    data =>
      axios.get("/api/work/customer/getDetail", {
        params: { seqNo: data },
      }),
    {
      onSuccess: res => {
        let temp = res.data.result;

        for (let key in temp) {
          if (temp[key] === null) {
            temp[key] = "";
          }

          if (key === "ownerList") {
            temp[key] = temp[key].map(el => {
              for (let key2 in el) {
                if (el[key2] === null) {
                  el[key2] = "";
                }
              }
              return el;
            });
          }
        }

        temp.juminno1 = temp.juminno?.slice(0, 6);
        temp.juminno2 = temp.juminno?.slice(6, 13);
        temp.juminno2Temp =
          temp.juminno.slice(6, 8) + temp.juminno.slice(8, 13).replace(/[0-9]/g, "*");
        temp.memberJuminno1 = temp.memberJuminno.slice(0, 6);
        temp.memberJuminno2 = temp.memberJuminno?.slice(6, 13);
        temp.memberJuminno2Temp =
          temp.memberJuminno.slice(6, 8) + temp.memberJuminno.slice(8, 13).replace(/[0-9]/g, "*");
        temp.inDate = moment(temp.inDate).format("YYYY-MM-DD");
        temp.ownerList = temp.ownerList.map(el => {
          return {
            ...el,
            ownerJuminno1: el.ownerJuminno?.slice(0, 6),
            ownerJuminno2: el.ownerJuminno?.slice(6, 13),
            ownerJuminno2Temp:
              el.ownerJuminno.slice(6, 8) + el.ownerJuminno.slice(8, 13).replace(/[0-9]/g, "*"),
          };
        });

        setMainData(temp);
        setIsDuplicateCheck(false);
      },
      onError: err => {
        if (err.response?.data.code === "-1") {
        }
      },
    }
  );

  // SMS 모달창 열기 전 권한 확인
  const customerCheckSmsAvailable = useMutation(
    data => axios.get("/api/work/customer/checkSmsAvailable", { params: data }),
    {
      onSuccess: res => {
        let tempList = searchedTableList.list
          .filter((el, idx) => searchedTableListChecked[idx])
          .filter(e => e.cellular && (e.hpCheck === "Y" || e.hpCheck2 === "Y"));

        const openSMSModal = data => setIsSendSMSModalOpen({ data, isOpen: true });

        const createTempData = () => ({
          cellular:
            mainData.mainCellular === "1" || mainData.mainCellular === ""
              ? mainData.cellular
              : mainData.ownerList[mainData.mainCellular - 2]?.ownerCelTel,
          custId: mainData.custId,
          dup: false,
          hpCheck: mainData.hpCheck === "Y" ? "Y" : "N",
          hpCheck2: mainData.hpCheck2 === "Y" ? "Y" : "N",
          isDuplication: "N",
          kname:
            mainData.mainCellular === "1" || mainData.mainCellular === ""
              ? mainData.kname
              : mainData.ownerList[mainData.mainCellular - 2]?.ownerName,
          position:
            mainData.mainCellular === "1" || mainData.mainCellular === ""
              ? mainData.position
              : mainData.ownerList[mainData.mainCellular - 2]?.ownerPosition,
          seqNo: mainData.seqNo,
        });

        if (decrypt("userInfo").userPermit === "99") {
          if (mainData.seqNo === "") {
            openSMSModal(tempList.length ? tempList : null);
          } else if (mainData.seqNo === "-1") {
            alert("신규등록을 먼저 해주세요");
          } else {
            openSMSModal(tempList.length ? tempList : [createTempData()]);
          }
        } else {
          if (mainData.seqNo === "-1") {
            alert("신규등록을 먼저 해주세요");
          } else {
            openSMSModal(tempList.length ? tempList : [createTempData()]);
          }
        }
      },
      onError: err => {
        if (err.response.data.code === "-1") {
          // alert(err.response.data.message);
        }
      },
    }
  );

  useEffect(() => {
    customerAlertCallBook.mutate();

    // 사이드바 클릭으로 접근할 때가 아니라 다른 페이지에서 접근할 때
    if (location.state) {
      btn_tableRowClick(location.state);

      window.history.replaceState({}, "");
    }

    // 컴포넌트 종료할 때 atom 데이터 초기화
    addTabClosedEvent(location.pathname, () => {
      setSearchData(customerManage_searchDataInit_atom);
      setTableLimit("5");
      setSearchedTableList(customerManage_searchedTableListInit_atom);
      setTapList([]);
      setSelectedCustomer("0");
      setMainData(customerManage_mainDataInit_atom);
      setWebOrderList([]);

      // setSubData([]);
    });
  }, []);

  // 검색조건 api 호출 완료 후 검색조건 데이터 초기화
  useEffect(() => {
    if (searchData.asCode === "" && getSearchCondition.isSuccess) {
      setSearchData({
        ...searchData,
        ...getSearchCondition?.data?.[0],
        asCode: getSearchCondition?.data?.[0]?.key,
      });
    }
  }, [getSearchCondition.isSuccess]);

  // 무한 스크롤 이벤트
  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && searchedTableList.list.length > 99) {
          setSearchData(prev => {
            const newSearchData = { ...prev };
            newSearchData.page = newSearchData.page + 1;

            // if (newSearchData.searchType === "1") {
            if (newSearchData.searchType === "1") {
              customerSearch.mutateAsync(newSearchData).then(res => {
                setSearchedTableList(prev3 => {
                  return {
                    ...prev3,
                    list: [...prev3.list, ...res.data.result.list],
                  };
                });
                setSearchedTableListChecked(prev => {
                  return Array.from(
                    { length: prev.length + res.data.result.list.length },
                    (_, idx) => prev?.[idx] ?? false
                  );
                });
              });
              // } else if (newSearchData.searchType === "2") {
            } else if (newSearchData.searchType === "2") {
              customerSearchFilter.mutateAsync(newSearchData).then(res => {
                setSearchedTableList(prev2 => {
                  return {
                    ...prev2,
                    list: [...prev2.list, ...res.data.result.list],
                  };
                });
                setSearchedTableListChecked(prev => {
                  return Array.from(
                    { length: prev.length + res.data.result.list.length },
                    (_, idx) => prev?.[idx] ?? false
                  );
                });
              });
              // } else if (newSearchData.searchType === "3") {
            } else if (newSearchData.searchType === "3") {
              customerSearchDetail.mutateAsync(newSearchData).then(res => {
                setSearchedTableList(prev4 => {
                  return {
                    ...prev4,
                    list: [...prev4.list, ...res.data.result.list],
                  };
                });
                setSearchedTableListChecked(prev => {
                  return Array.from(
                    { length: prev.length + res.data.result.list.length },
                    (_, idx) => prev?.[idx] ?? false
                  );
                });
              });
            }

            return newSearchData;
          });
        }
      },
      { threshold: 0.5 }
    );

    if (infinityScroll) {
      observer.observe(infinityScroll);
    }
  }, [infinityScroll]);

  useEffect(() => {
    setSearchedTableListChecked(
      Array.from({ length: searchedTableList?.list?.length }, (_, index) => {
        {
          if (searchedTableListChecked[index]) {
            return true;
          } else {
            return false;
          }
        }
      })
    );
  }, [searchedTableList]);

  const btn_search = () => {
    // searchData.searchType 설명:  "1": 기본검색 / "2": 트리검색 / "3": 상세검색

    if (searchData.searchType === "1" || searchData.searchType === "2") {
      let filter = {
        ...searchData,
        page: 1,
        selectOption: null,
        isRankSelectActive: null,
        rankSelect: null,
        migrateDay: 0,
      };

      if (filter.asCode === "115") {
        // 115 휴대폰 검색조건
        filter = { ...filter, keyword: filter.keyword.replaceAll(/\-/g, "") };

        if (filter.keyword.length < 10) {
          alert("휴대폰 번호를 정확히 입력해주세요.");
          return;
        }
      } else if (filter.asCode === "105") {
        // 105 관리번호 검색조건
        filter = { ...filter, keyword: filter.keyword.replaceAll(/\-/g, "") };

        if (filter.keyword.length < 8) {
          alert("관리번호를 정확히 입력해주세요.");
          return;
        }
      } else if (
        filter.asCode === "107" ||
        filter.asCode === "108" ||
        filter.asCode === "114" ||
        filter.asCode === "112" ||
        filter.asCode === "128" ||
        filter.asCode === "129"
      ) {
        // 107 보유회원권 검색조건
        // 108 거래회원권 검색조건
        // 114 추천인조회-종목 검색조건
        // 112 골프종목별주문조회 검색조건
        // 128 콘도종목별주문조회 검색조건
        // 129 헬스종목별주문조회 검색조건
        if (
          window.confirm(
            `예(Y)를 선택하시면 --> '${
              filter.radio === "GM"
                ? "골프장"
                : filter.radio === "CM"
                  ? "콘도"
                  : filter.radio === "SM" && "헬스"
            } 마스터'에서 조회\n취소(N)를 선택하시면 --> '회원권 종목명'으로 조회합니다`
          )
        ) {
          filter = { ...filter, searchTypeSwitch: 1 };
        } else {
          filter = { ...filter, searchTypeSwitch: 2 };
        }
      }

      setSearchedTableList(() => customerManage_searchedTableListInit_atom);

      customerSearch
        .mutateAsync(filter)
        .then(res => {
          if (res.data.result.list.length === 0) {
            alert("해당 검색어로 조회하신 내용은 존재하지 않습니다...!!");
          }

          setSearchedTableList(res.data.result);
          setSearchData({
            ...filter,
            searchType: "1",
          });
          if (filter.page === 1) setLastCheckedIndex(-1);
        })
        .catch(err => {
          if (err.response.data.code === "-5") {
            if (window.confirm(err.response.data.message)) {
              customerSearch
                .mutateAsync({ ...filter, again: true })
                .then(res => {
                  setSearchedTableList(res.data.result);
                  setSearchData({
                    ...filter,
                    searchType: "1",
                  });
                  // setIsDetailSearch(false);
                })
                .catch(err => {
                  if (err.response.data.code === "-6") {
                    if (window.confirm(err.response.data.message)) {
                      btn_customerRegister();
                    }
                  }
                });
            }
          } else if (err.response.data.code === "-6") {
            if (window.confirm(err.response.data.message)) {
              btn_customerRegister();
            }
          }
        });
    } else if (searchData.searchType === "3") {
      let filter = { ...searchData };
      filter = { ...filter, page: 1 };

      customerSearchDetail.mutateAsync(filter).then(res => {
        setSearchedTableList({
          ...searchedTableList,
          list: res.data.result.list,
          total: res.data.result.total,
          dagolfExist: res.data.result.dagolfExist,
          dagolfNew: res.data.result.dagolfNew,
          dagolfRevoc: res.data.result.dagolfRevoc,
          exchangeA: res.data.result.exchangeA,
          exchangeB: res.data.result.exchangeB,
          exchangeMineCnt: res.data.result.exchangeMineCnt,
        });

        setSearchData(filter);
      });
    }
  };

  const btn_search_treeNode = (code, base) => {
    let filter = searchData;

    filter = { ...filter, page: 1, groupCode: code, gubunBase: base, searchType: "2" };

    customerSearchFilter.mutateAsync(filter).then(res => {
      setSearchedTableList({
        ...searchedTableList,
        list: res.data.result.list,
        total: res.data.result.total,
        // dagolfExist: res.data.result.dagolfExist,
        // dagolfNew: res.data.result.dagolfNew,
        // dagolfRevoc: res.data.result.dagolfRevoc,
        // exchangeA: res.data.result.exchangeA,
        // exchangeB: res.data.result.exchangeB,
        // exchangeMineCnt: res.data.result.exchangeMineCnt,
      });

      setSearchData(filter);
    });
  };

  const btn_search_detail = () => {
    if (searchData.searchType === "3") {
      // setIsDetailSearch(false);
      setSearchData({
        ...searchData,
        selectOption: null,
        searchType: "1",
        gubun: null,
        migrateDay: null,
      });
    } else {
      // setIsDetailSearch(true);
      setSearchData({
        ...searchData,
        selectOption: "1",
        searchType: "3",
        gubun: "",
        migrateDay: 0,
      });
    }
  };

  const btn_customerRegister = () => {
    // if (
    //   searchData.asCode !== "101" &&
    //   searchData.asCode !== "102" &&
    //   searchData.asCode !== "103" &&
    //   searchData.asCode !== "104" &&
    //   searchData.asCode !== "115"
    // ) {
    //   alert("해당선택어로는 등록할 수가 없습니다.");
    //   return;
    // }

    if (window.confirm("[주문, 인터넷 오더 또는 회원권 상담]으로 신규 등록입니까?")) {
      setMainData({
        ...customerManage_mainDataInit_atom,
        gubun: getCustGubun.data.find(el => el.value === "주문고객").key,
        jisa: decrypt("userInfo").jisa,
        seqNo: "-1",
        juminno1: "",
        juminno2: "",
        juminno2Temp: "",
        memberJuminno1: "",
        memberJuminno2: "",
        memberJuminno2Temp: "",
        ownerList: [],
        vipCheck: "N",
        oldPartner: "",
        partner: getPartner.data.find(el => el.value === decrypt("userInfo").userName).key,
      });
    } else {
      setMainData({
        ...customerManage_mainDataInit_atom,
        gubun: getCustGubun.data.find(el => el.value === "신규법인").key,
        jisa: decrypt("userInfo").jisa,
        seqNo: "-1",
        juminno1: "",
        juminno2: "",
        juminno2Temp: "",
        memberJuminno1: "",
        memberJuminno2: "",
        memberJuminno2Temp: "",
        ownerList: [],
        vipCheck: "N",
        oldPartner: "",
        partner: getPartner.data.find(el => el.value === decrypt("userInfo").userName).key,
      });
    }

    setSelectedCustomer("-1");

    setTapList(prev => {
      return [
        {
          seqNo: "-1",
          kname: "신규고객",
        },
        ...prev,
      ];
    });
  };

  const btn_tableRowClick = data => {
    if (selectedCustomer === "-1") {
      alert("신규고객등록 부터 처리해주세요.");
      return;
    }

    if (!tapList.some(el => el.seqNo === data.seqNo)) {
      setTapList(prev => {
        return [data, ...prev];
      });
    }

    if (customerGetDetail.isLoading) {
      alert("서버에서 불러오는 중입니다.");
      return;
    }

    if (tapList.length > 7) {
      // 마지막에 있는 배열 삭제하고 0번째에 새로운 data 추가
      setTapList(prev => [data, ...prev.slice(1, 8)]);
    }

    setSelectedCustomer(data.seqNo);
  };

  const btn_SMSSend = () => {
    customerCheckSmsAvailable.mutate({ custId: mainData.custId !== "" ? mainData.custId : null });
  };

  const pressEnter = e => {
    if (e.key === "Enter") {
      btn_search();
    }
  };

  return (
    <>
      {isProcessingResultModalOpen.isOpen && (
        <ProcessingResultModal
          isProcessingResultModalOpen={isProcessingResultModalOpen}
          setIsProcessingResultModalOpen={setIsProcessingResultModalOpen}
        />
      )}

      {isCallReservationModalOpen.isOpen && (
        <CallReservationModal
          isCallReservationModalOpen={isCallReservationModalOpen}
          setIsCallReservationModalOpen={setIsCallReservationModalOpen}
        />
      )}

      {isSendSMSModalOpen.isOpen && (
        <SendSMSModal
          isSendSMSModalOpen={isSendSMSModalOpen}
          setIsSendSMSModalOpen={setIsSendSMSModalOpen}
        />
      )}

      {isSendSMSConfirmModalOpen && (
        <SendSMSConfirmModal
          isSendSMSConfirmModalOpen={isSendSMSConfirmModalOpen}
          setIsSendSMSConfirmModalOpen={setIsSendSMSConfirmModalOpen}
        />
      )}

      <div className="content-inner">
        {/* 고객 검색 영역 */}
        <div className="filter1_container">
          <div className="filter_header_row">
            <div className="filter-title">고객 검색</div>
            <div className="filter-buttons">
              <button
                className="btn btn-outline-dark my-btn-outline-dark"
                onClick={() =>
                  setIsProcessingResultModalOpen({
                    data: null,
                    isOpen: true,
                  })
                }
              >
                제명(복구)처리결과
              </button>
              <button
                className="btn btn-outline-dark my-btn-outline-dark"
                onClick={() => {
                  setIsCallReservationModalOpen({
                    data: mainData,
                    isOpen: true,
                  });
                }}
                disabled={selectedCustomer === "0" || selectedCustomer === "-1"}
              >
                통화예약
              </button>
              <button className="btn btn-outline-dark my-btn-outline-dark" onClick={btn_SMSSend}>
                SMS 발송
              </button>
              <button
                className="btn btn-outline-dark my-btn-outline-dark"
                onClick={() => {
                  if (window.confirm("견적서로 이동하시겠습니까?")) {
                    sidebarOnClickEvent({
                      name: "견적서 관리",
                      url: "/data/estimate/quotationManagement",
                      state: {
                        mainData: {
                          ...mainData,
                          email:
                            mainData.egubun === "99"
                              ? mainData.email
                              : mainData.email +
                                "@" +
                                getEmail.data?.find(el2 => el2.key === mainData.egubun)?.value,
                        },
                      },
                    });
                  }
                }}
                disabled={selectedCustomer === "0" || selectedCustomer === "-1"}
              >
                견적서 바로가기
              </button>
              <button
                className="btn btn-outline-dark my-btn-outline-dark"
                onClick={() =>
                  setIsSendSMSConfirmModalOpen({
                    data: null,
                    isOpen: true,
                  })
                }
              >
                문자 전송 확인
              </button>
              <button
                className="btn btn-primary btn-table-filter"
                onClick={btn_customerRegister}
                disabled={
                  selectedCustomer === "-1" ||
                  getCustGubun.isLoading ||
                  getSearchCondition.isLoading ||
                  getDb.isLoading ||
                  getAsCode127Keyword.isLoading ||
                  getEtccodeDw.isLoading
                }
              >
                신규등록
              </button>
            </div>
          </div>

          <div className="filter_fields_container">
            <div className="filter_fields_row">
              <div
                className="d-flex align-items-center justify-content-between"
                onKeyDown={pressEnter}
              >
                <div className="filter1-field filter1-field1">
                  <label className="filter1-label">검색조건</label>

                  <select
                    className="form-select filter1-select1 lg"
                    value={searchData.asCode}
                    onChange={e => {
                      if (e.target.value === "0") {
                        return;
                      }

                      if (e.target.value === "113") {
                        // 미통화고객 검색조건
                        setSearchData({
                          ...searchData,
                          ...getSearchCondition.data.find(el => el.key === e.target.value),
                          keyword: moment().format("YYYY-MM-DD"),
                          asCode: e.target.value,
                          includeSms: true,
                          pastOwner: null,
                          radio: null,
                          page: 1,
                          searchType: "1",
                        });
                      } else if (e.target.value === "107") {
                        // 보유회원권
                        setSearchData({
                          ...searchData,
                          ...getSearchCondition.data.find(el => el.key === e.target.value),
                          keyword: "",
                          asCode: e.target.value,
                          includeSms: null,
                          pastOwner: false,
                          radio: "GM",
                          page: 1,
                          searchType: "1",
                        });
                      } else if (e.target.value === "108" || e.target.value === "114") {
                        // 거래회원권 검색조건
                        // 추천인조회-종목 검색조건
                        setSearchData({
                          ...searchData,
                          ...getSearchCondition.data.find(el => el.key === e.target.value),
                          keyword: "",
                          asCode: e.target.value,
                          includeSms: null,
                          pastOwner: null,
                          radio: "GM",
                          page: 1,
                          searchType: "1",
                        });
                      } else if (e.target.value === "126") {
                        // 오다매칭조회 검색조건
                        setSearchData({
                          ...searchData,
                          ...getSearchCondition.data.find(el => el.key === e.target.value),
                          keyword: "1",
                          asCode: e.target.value,
                          includeSms: null,
                          pastOwner: null,
                          radio: null,
                          page: 1,
                          searchType: "1",
                        });
                      } else if (e.target.value === "111") {
                        // 주문관리 검색조건
                        setSearchData({
                          ...searchData,
                          ...getSearchCondition.data.find(el => el.key === e.target.value),
                          keyword: "1",
                          asCode: e.target.value,
                          includeSms: null,
                          pastOwner: null,
                          radio: null,
                          page: 1,
                          searchType: "1",
                        });
                      } else if (e.target.value === "133") {
                        // 회원권보유 구분 검색조건
                        setSearchData({
                          ...searchData,
                          ...getSearchCondition.data.find(el => el.key === e.target.value),
                          keyword: "1",
                          asCode: e.target.value,
                          includeSms: null,
                          pastOwner: null,
                          radio: null,
                          page: 1,
                          searchType: "1",
                        });
                      } else if (e.target.value === "118") {
                        // 생일자조회 검색조건
                        setSearchData({
                          ...searchData,
                          ...getSearchCondition.data.find(el => el.key === e.target.value),
                          keyword: "0",
                          asCode: e.target.value,
                          includeSms: null,
                          pastOwner: null,
                          radio: null,
                          page: 1,
                          searchType: "1",
                        });
                      } else if (e.target.value === "127") {
                        // 업종별(본인관리內)
                        setSearchData({
                          ...searchData,
                          ...getSearchCondition.data.find(el => el.key === e.target.value),
                          keyword: "1001",
                          asCode: e.target.value,
                          includeSms: null,
                          pastOwner: null,
                          radio: null,
                          page: 1,
                          searchType: "1",
                        });
                      } else if (e.target.value === "132") {
                        // 명단출처로 조회 검색조건
                        setSearchData({
                          ...searchData,
                          ...getSearchCondition.data.find(el => el.key === e.target.value),
                          keyword: "1022",
                          asCode: e.target.value,
                          includeSms: null,
                          pastOwner: null,
                          radio: null,
                          page: 1,
                          searchType: "1",
                        });
                      } else if (
                        e.target.value === "119" ||
                        e.target.value === "120" ||
                        e.target.value === "130"
                      ) {
                        // 119 일자별 통화업체 검색조건
                        // 120 통화 예약 업체 검색조건
                        // 130 등록일자 검색조건
                        setSearchData({
                          ...searchData,
                          ...getSearchCondition.data.find(el => el.key === e.target.value),
                          keyword: moment().format("YYYY-MM-DD"),
                          asCode: e.target.value,
                          includeSms: null,
                          pastOwner: null,
                          radio: null,
                          page: 1,
                          searchType: "1",
                        });
                      } else if (
                        e.target.value === "112" ||
                        e.target.value === "128" ||
                        e.target.value === "129"
                      ) {
                        // 112 골프종목별주문조회
                        // 128 콘도종목별주문조회
                        // 129 헬스종목별주문조회
                        setSearchData({
                          ...searchData,
                          ...getSearchCondition.data.find(el => el.key === e.target.value),
                          keyword: "",
                          asCode: e.target.value,
                          includeSms: null,
                          pastOwner: null,
                          page: 1,
                          searchType: "1",
                        });
                      } else {
                        setSearchData({
                          ...searchData,
                          ...getSearchCondition.data.find(el => el.key === e.target.value),
                          keyword: "",
                          asCode: e.target.value,
                          includeSms: null,
                          pastOwner: null,
                          radio: null,
                          page: 1,
                          searchType: "1",
                        });
                      }
                    }}
                  >
                    {getSearchCondition?.data?.map(el => (
                      <option value={el.key}>{el.value}</option>
                    ))}
                  </select>
                </div>

                <div className="filter1-field filter1-field2">
                  <label className="filter1-label">검색어</label>
                  {searchData.asCode !== "126" &&
                    searchData.asCode !== "111" &&
                    searchData.asCode !== "133" &&
                    searchData.asCode !== "132" &&
                    searchData.asCode !== "118" &&
                    searchData.asCode !== "127" && (
                      <input
                        type={`${
                          searchData.asCode === "113" ||
                          searchData.asCode === "119" ||
                          searchData.asCode === "120" ||
                          searchData.asCode === "130"
                            ? "date"
                            : "text"
                        }`}
                        className="form-control filter-input filter1-input2"
                        value={searchData.keyword}
                        onChange={e => {
                          setSearchData({ ...searchData, keyword: e.target.value });
                        }}
                        max="9999-12-31"
                        placeholder={searchData.asCode === "106" && "지역명/아파트명"}
                      />
                    )}

                  {searchData.asCode === "126" && (
                    // 오다매칭조회 검색조건
                    <select
                      className="form-select table-filter-select"
                      value={searchData.keyword}
                      onChange={e => {
                        setSearchData({ ...searchData, keyword: e.target.value });
                      }}
                    >
                      <option value="1">골프매도매칭[본인꺼]</option>
                      <option value="2">골프매수매칭[본인꺼]</option>
                      <option value="6">골프주문매칭[총괄자]</option>
                    </select>
                  )}

                  {searchData.asCode === "111" && (
                    // 주문관리 검색조건
                    <select
                      className="form-select table-filter-select"
                      value={searchData.keyword}
                      onChange={e => {
                        setSearchData({ ...searchData, keyword: e.target.value });
                      }}
                    >
                      <option value="1">진행,보류[본인-관리,상담]</option>
                      <option value="2">타 샵거래[본인-관리,상담]</option>
                    </select>
                  )}

                  {searchData.asCode === "133" && (
                    // 회원권보유 구분 검색조건
                    <select
                      className="form-select table-filter-select"
                      value={searchData.keyword}
                      onChange={e => {
                        setSearchData({ ...searchData, keyword: e.target.value });
                      }}
                    >
                      {getEtccodeDw?.data?.map(el => (
                        <option value={el.key}>{el.value}</option>
                      ))}
                    </select>
                  )}

                  {searchData.asCode === "118" && (
                    // 생일자조회 검색조건
                    <select
                      className="form-select table-filter-select"
                      value={searchData.keyword}
                      onChange={e => {
                        setSearchData({ ...searchData, keyword: e.target.value });
                      }}
                    >
                      <option value="0">오늘생일</option>
                      <option value="6">1주일이내</option>
                      <option value="13">2주일이내</option>
                      <option value="30">1개월이내</option>
                    </select>
                  )}

                  {searchData.asCode === "127" && (
                    // 업종별(본인관리內)
                    <select
                      className="form-select table-filter-select"
                      value={searchData.keyword}
                      onChange={e => {
                        setSearchData({ ...searchData, keyword: e.target.value });
                      }}
                    >
                      {selectGetUp?.data?.map(el => (
                        <option value={el.key}>{el.value}</option>
                      ))}
                    </select>
                  )}

                  {searchData.asCode === "132" && (
                    // 명단출처로 조회 검색조건
                    <select
                      className="form-select table-filter-select"
                      value={searchData.keyword}
                      onChange={e => {
                        setSearchData({ ...searchData, keyword: e.target.value });
                      }}
                    >
                      {getDb?.data?.map(el => (
                        <option value={el.key}>{el.value}</option>
                      ))}
                    </select>
                  )}

                  {searchData.asCode === "113" && (
                    // 미통화고객 검색조건
                    <>
                      <label className="filter1-label" style={{ marginLeft: "10px" }}>
                        일 이후
                      </label>
                      <div className="filter1-field">
                        <input
                          type="checkbox"
                          id="chk-1"
                          className="form-check-input"
                          name="checkbox-option"
                          checked={searchData.includeSms}
                          onChange={e => {
                            setSearchData({ ...searchData, includeSms: e.target.checked });
                          }}
                        />
                        <label className="filter1-label" htmlFor="chk-1">
                          문자 포함
                        </label>
                      </div>
                    </>
                  )}
                </div>

                <div className="filter1-field filter1-field3">
                  <input
                    type="checkbox"
                    id="chk-1"
                    className="form-check-input"
                    name="checkbox-option"
                    onChange={e => {
                      setSearchData({ ...searchData, mngByMe: e.target.checked });
                    }}
                    checked={searchData.mngByMe}
                    disabled={
                      // !getSearchCondition?.find(el => el.key === searchData?.asCode)?.enableMngByMe
                      searchData?.enableMngByMe !== true
                    }
                  />
                  <label className="filter1-label" htmlFor="chk-1">
                    본인관리 내 검색
                  </label>
                </div>
                <div className="filter1-field filter1-field4">
                  <input
                    type="checkbox"
                    id="chk-2"
                    className="form-check-input"
                    name="checkbox-option"
                    onChange={e => {
                      setSearchData({ ...searchData, mngPlace: e.target.checked });
                    }}
                    checked={searchData.mngPlace}
                    disabled={
                      // !getSearchCondition?.find(el => el.key === searchData?.asCode)?.enableMngPlace
                      searchData?.enableMngPlace !== true
                    }
                  />
                  <label className="filter1-label" htmlFor="chk-2">
                    관리지사 內 검색
                  </label>
                </div>
                <div className="filter1-field filter1-field5">
                  <input
                    type="checkbox"
                    id="chk-3"
                    className="form-check-input"
                    name="checkbox-option"
                    onChange={e => {
                      setSearchData({ ...searchData, includeEx: e.target.checked });
                    }}
                    checked={searchData.includeEx}
                  />
                  <label className="filter1-label" htmlFor="chk-3">
                    제명 포함
                  </label>
                </div>

                {searchData.pastOwner !== null && (
                  <div className="filter1-field filter1-field1">
                    <label className="filter1-label">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        name="checkbox-option"
                        checked={searchData.pastOwner}
                        onChange={e => {
                          setSearchData({ ...searchData, pastOwner: e.target.checked });
                        }}
                      />
                      이전 보유자
                    </label>
                  </div>
                )}
              </div>

              <div className="d-flex">
                <button
                  className="btn btn-outline-primary btn-filter-outline-primary me-20px"
                  onClick={btn_search}
                  disabled={
                    customerSearch.isLoading ||
                    getCustGubun.isLoading ||
                    getSearchCondition.isLoading ||
                    getDb.isLoading ||
                    getAsCode127Keyword.isLoading ||
                    getEtccodeDw.isLoading
                  }
                >
                  <img
                    className="me-2"
                    src={process.env.PUBLIC_URL + "/images/search_icon.png"}
                    alt=""
                  />
                  검색
                </button>
              </div>
            </div>

            <div className="filter_fields_row">
              <div className="d-flex">
                {(searchData.asCode === "107" ||
                  searchData.asCode === "108" ||
                  searchData.asCode === "114") && (
                  // 보유회원권 || 거래회원권 || 추천인조회-종목
                  <div className="filter1-field filter1-field1">
                    <label className="filter1-label">
                      <input
                        type="radio"
                        id="chk-1"
                        className="form-check-input"
                        name="checkbox-option"
                        value="GM"
                        checked={searchData.radio === "GM"}
                        onChange={e => {
                          setSearchData({
                            ...searchData,
                            radio: e.target.value,
                          });
                        }}
                      />
                      골프
                    </label>
                    <label className="filter1-label">
                      <input
                        type="radio"
                        id="chk-1"
                        className="form-check-input"
                        name="checkbox-option"
                        value="CM"
                        checked={searchData.radio === "CM"}
                        onChange={e => {
                          setSearchData({
                            ...searchData,
                            radio: e.target.value,
                          });
                        }}
                      />
                      콘도
                    </label>
                    <label className="filter1-label">
                      <input
                        type="radio"
                        id="chk-1"
                        className="form-check-input"
                        name="checkbox-option"
                        value="SM"
                        checked={searchData.radio === "SM"}
                        onChange={e => {
                          setSearchData({
                            ...searchData,
                            radio: e.target.value,
                          });
                        }}
                      />
                      휘트니스
                    </label>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="card card-sub">
          <div className="card-body">
            {/* 검색조건이 "본인 할당-업체명,이름"일 때 나타나야할 트리  */}
            <div className="table-tree-wrapper">
              {searchData?.asCode === "117" && (
                <div className="tree-outer">
                  <div className="tree-title" style={{ height: "fit-content" }}>
                    검색 건수:{" "}
                    <span>{searchedTableList.exchangeA + searchedTableList.exchangeB}</span>명
                    (본인거래:
                    <span>{searchedTableList.exchangeB}</span>명/{" "}
                    <span>{searchedTableList.exchangeMineCnt}</span>건)
                  </div>
                  <div
                    className="tree-container"
                    style={{
                      height: `${
                        tableLimit === "5" ? "183px" : tableLimit === "10" ? "333px" : "633px"
                      }`,
                    }}
                  >
                    {searchedTableList.tree?.length !== 0 && (
                      <div className="my-treeview-wrapper">
                        <ul className="my-treeview">
                          <li>
                            <div
                              onClick={() =>
                                btn_search_treeNode(
                                  searchedTableList.tree[0].groupCode,
                                  searchedTableList.tree[0].gubunBase
                                )
                              }
                            >
                              내고객 전체 ({searchedTableList.tree?.[0]?.groupCnt ?? 0})
                            </div>
                            <ul>
                              <li>
                                <div
                                  onClick={() =>
                                    btn_search_treeNode(
                                      searchedTableList.tree[1].groupCode,
                                      searchedTableList.tree[1].gubunBase
                                    )
                                  }
                                >
                                  내가 거래한 고객({searchedTableList.tree?.[1]?.groupCnt ?? 0})
                                </div>
                              </li>
                              <li>
                                <div
                                  onClick={() =>
                                    btn_search_treeNode(
                                      searchedTableList.tree[2].groupCode,
                                      searchedTableList.tree[2].gubunBase
                                    )
                                  }
                                >
                                  다른 직원이 거래한 고객(
                                  {searchedTableList.tree?.[2]?.groupCnt ?? 0})
                                </div>
                              </li>
                              <li>
                                <div
                                  onClick={() =>
                                    btn_search_treeNode(
                                      searchedTableList.tree[3].groupCode,
                                      searchedTableList.tree[3].gubunBase
                                    )
                                  }
                                >
                                  타샵 거래 고객({searchedTableList.tree?.[3]?.groupCnt ?? 0})
                                </div>
                              </li>
                              <li>
                                <div
                                  onClick={() =>
                                    btn_search_treeNode(
                                      searchedTableList.tree[4].groupCode,
                                      searchedTableList.tree[4].gubunBase
                                    )
                                  }
                                >
                                  회원권 보유 고객({searchedTableList.tree?.[4]?.groupCnt ?? 0})
                                </div>
                              </li>
                              <li>
                                <div
                                  onClick={() =>
                                    btn_search_treeNode(
                                      searchedTableList.tree[5].groupCode,
                                      searchedTableList.tree[5].gubunBase
                                    )
                                  }
                                >
                                  일반 고객({searchedTableList.tree?.[5]?.groupCnt ?? 0})
                                </div>
                                <ul>
                                  <li>
                                    <div
                                      onClick={() =>
                                        btn_search_treeNode(
                                          searchedTableList.tree[6].groupCode,
                                          searchedTableList.tree[6].gubunBase
                                        )
                                      }
                                    >
                                      A등급({searchedTableList.tree?.[6]?.groupCnt ?? 0})
                                    </div>
                                  </li>
                                  <li>
                                    <div
                                      onClick={() =>
                                        btn_search_treeNode(
                                          searchedTableList.tree[7].groupCode,
                                          searchedTableList.tree[7].gubunBase
                                        )
                                      }
                                    >
                                      B등급({searchedTableList.tree?.[7]?.groupCnt ?? 0})
                                    </div>
                                  </li>
                                  <li>
                                    <div
                                      onClick={() =>
                                        btn_search_treeNode(
                                          searchedTableList.tree[8].groupCode,
                                          searchedTableList.tree[8].gubunBase
                                        )
                                      }
                                    >
                                      법인전용({searchedTableList.tree?.[8]?.groupCnt ?? 0})
                                    </div>
                                  </li>
                                  <li>
                                    <div
                                      onClick={() =>
                                        btn_search_treeNode(
                                          searchedTableList.tree[9].groupCode,
                                          searchedTableList.tree[9].gubunBase
                                        )
                                      }
                                    >
                                      C등급({searchedTableList.tree?.[9]?.groupCnt ?? 0})
                                    </div>
                                  </li>
                                  <li>
                                    <div
                                      onClick={() =>
                                        btn_search_treeNode(
                                          searchedTableList.tree[10].groupCode,
                                          searchedTableList.tree[10].gubunBase
                                        )
                                      }
                                    >
                                      무등급({searchedTableList.tree?.[10]?.groupCnt ?? 0})
                                    </div>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <div
                                  onClick={() =>
                                    btn_search_treeNode(
                                      searchedTableList.tree[11].groupCode,
                                      searchedTableList.tree[11].gubunBase
                                    )
                                  }
                                >
                                  핸드폰-有無({searchedTableList.tree?.[11]?.groupCnt ?? 0})
                                </div>
                                <ul>
                                  <li>
                                    <div
                                      onClick={() =>
                                        btn_search_treeNode(
                                          searchedTableList.tree[12].groupCode,
                                          searchedTableList.tree[12].gubunBase
                                        )
                                      }
                                    >
                                      핸드폰-有({searchedTableList.tree?.[12]?.groupCnt ?? 0})
                                    </div>
                                  </li>
                                  <li>
                                    <div
                                      onClick={() =>
                                        btn_search_treeNode(
                                          searchedTableList.tree[13].groupCode,
                                          searchedTableList.tree[13].gubunBase
                                        )
                                      }
                                    >
                                      핸드폰-無({searchedTableList.tree?.[13]?.groupCnt ?? 0})
                                    </div>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <div
                                  onClick={() =>
                                    btn_search_treeNode(
                                      searchedTableList.tree[14].groupCode,
                                      searchedTableList.tree[14].gubunBase
                                    )
                                  }
                                >
                                  특이사항({searchedTableList.tree?.[14]?.groupCnt ?? 0})
                                </div>
                              </li>
                              <li>
                                <div
                                  onClick={() =>
                                    btn_search_treeNode(
                                      searchedTableList.tree[15].groupCode,
                                      searchedTableList.tree[15].gubunBase
                                    )
                                  }
                                >
                                  제명고객({searchedTableList.tree?.[15]?.groupCnt ?? 0})
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div className="table-outer">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="table-count" style={{ height: "fit-content" }}>
                    검색 건수: <span>{`${searchedTableList?.total ?? 0}`}</span> 명
                  </div>
                  <div className="d-flex align-items-center">
                    {searchData.asCode === "117" && searchedTableList.list.length > 0 && (
                      <label className="filter1-label">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={searchData.searchType === "3"}
                          onChange={btn_search_detail}
                        />
                        세부검색
                      </label>
                    )}
                    <select
                      className="form-select table-filter-select"
                      value={tableLimit}
                      onChange={e => {
                        setTableLimit(e.target.value);
                      }}
                    >
                      <option value="5">5개씩 보기</option>
                      <option value="10">10개씩 보기</option>
                      <option value="20">20개씩 보기</option>
                    </select>
                  </div>
                </div>

                {searchData.searchType === "3" && (
                  <div className="d-flex justify-content-end mt-2 mb-2" onKeyDown={pressEnter}>
                    <div className="d-flex me-4">
                      <label className="filter1-label">2차 검색조건 선택</label>
                      <select
                        className="form-select table-filter-select"
                        value={searchData.selectOption}
                        onChange={e => {
                          if (e.target.value === "5") {
                            setSearchData({
                              ...searchData,
                              selectOption: e.target.value,
                              isRankSelectActive: false,
                              rankSelect: "",
                            });
                          } else {
                            setSearchData({
                              ...searchData,
                              selectOption: e.target.value,
                              isRankSelectActive: false,
                              rankSelect: null,
                            });
                          }
                        }}
                      >
                        <option value="*">전체보기</option>
                        <option value="1">거래자 전체(타샵포함)</option>
                        <option value="2">본인이 거래한 명단</option>
                        <option value="3">다른 담당자가 거래한 명단</option>
                        <option value="4">타샵거래자</option>
                        <option value="5">비거래만 보기</option>
                      </select>
                    </div>

                    {searchData.selectOption === "5" && (
                      <div className="d-flex">
                        <input
                          type="checkbox"
                          id="chk-99"
                          className="form-check-input"
                          name="checkbox-option"
                          checked={searchData.isRankSelectActive}
                          onChange={e => {
                            setSearchData({
                              ...searchData,
                              isRankSelectActive: e.target.checked,
                              rankSelect: e.target.checked ? "Y" : "",
                            });
                          }}
                        />
                        <label className="filter1-label" htmlFor="chk-99">
                          등급별 선택
                        </label>

                        <select
                          className="form-select table-filter-select"
                          value={searchData.rankSelect}
                          onChange={e => {
                            setSearchData({ ...searchData, rankSelect: e.target.value });
                          }}
                          disabled={!searchData.isRankSelectActive}
                        >
                          <option value="Y">A등급</option>
                          <option value="S">B등급</option>
                          <option value="M">C등급</option>
                          <option value="C">법인전용</option>
                          <option value="N">무등급</option>
                        </select>
                      </div>
                    )}

                    <div className="d-flex">
                      <label className="filter1-label">3차 검색조건 선택</label>
                      <select
                        className="form-select table-filter-select"
                        value={searchData.gubun}
                        onChange={e => {
                          if (e.target.value === "M") {
                            setSearchData({
                              ...searchData,
                              gubun: e.target.value,
                              migrateDay: 60,
                            });
                          } else {
                            setSearchData({
                              ...searchData,
                              gubun: e.target.value,
                              migrateDay: 0,
                            });
                          }
                        }}
                      >
                        <option value="">해당없음</option>
                        <option value="M">최근 이관받은 명단</option>
                        <option value="R">담당자 휴대폰 有 고객만</option>
                        <option value="T">유선 有+휴대폰 無 조회</option>
                        <option value="F">연락처 없는 고객</option>
                        <option value="H">휴대폰 有 고객만 (주고객)</option>
                      </select>
                    </div>

                    {searchData.gubun === "M" && (
                      <div className="d-flex">
                        <input
                          type="text"
                          className="form-control filter1-input2"
                          value={searchData.migrateDay}
                          onChange={e => {
                            if (regex_only_number.test(e.target.value)) {
                              if (e.target.value > 1000) {
                                setSearchData({
                                  ...searchData,
                                  migrateDay: 999,
                                });
                                return;
                              }

                              if (e.target.value < 1) {
                                setSearchData({
                                  ...searchData,
                                  migrateDay: 1,
                                });
                                return;
                              }

                              setSearchData({
                                ...searchData,
                                migrateDay: e.target.value,
                              });
                            }
                          }}
                        />
                        <label className="filter1-label">일 이내</label>
                      </div>
                    )}
                  </div>
                )}

                {/* 검색된 고객 테이블 영역 */}
                <div
                  className={`table-container table-sticky mt-2
                ${tableLimit === "5" && "h-183"}
                ${tableLimit === "10" && "h-333"}
                ${tableLimit === "20" && "h-633"}`}
                >
                  <table className="table table-bordered table-responsive my-table mt-0">
                    <colgroup>
                      <col width="4%" />
                      <col width="5%" />
                      <col width="10%" />
                      <col width="20%" />
                      <col width="20%" />
                      <col width="20%" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>
                          <input
                            type="checkbox"
                            checked={
                              searchedTableListChecked.length !== 0 &&
                              searchedTableListChecked.every(el => el === true)
                            }
                            onChange={e => {
                              setSearchedTableListChecked(
                                Array.from(
                                  { length: searchedTableListChecked.length },
                                  () => e.target.checked
                                )
                              );
                            }}
                          />
                        </th>
                        <th>No.</th>
                        <th>관리번호</th>
                        <th>회사명</th>
                        <th>성명</th>
                        <th>직위</th>
                      </tr>
                    </thead>
                    <tbody>
                      {searchedTableList?.list?.length !== 0
                        ? searchedTableList.list?.map((el, idx) => {
                            return (
                              <tr
                                className={`cursor-pointer ${el.outCheck === "Y" ? "bg-red" : ""} ${
                                  el.compCust === 1 ? "bg-lightblue" : ""
                                } ${el.shopCust === 1 ? "bg-lightyellow" : ""} ${
                                  mainData?.custId === el?.custId ? "bg-blue" : ""
                                }`}
                                onClick={() => btn_tableRowClick(el)}
                              >
                                <td
                                  onClick={e => {
                                    e.stopPropagation();
                                    setSearchedTableListChecked(prev => {
                                      if (lastCheckedIndex > -1 && e.shiftKey) {
                                        let start = lastCheckedIndex < idx ? lastCheckedIndex : idx;
                                        let end = lastCheckedIndex < idx ? idx : lastCheckedIndex;
                                        for (let i = start; i <= end; i++) {
                                          prev[i] = e.target.checked;
                                        }
                                      } else prev[idx] = e.target.checked;
                                      return [...prev];
                                    });
                                    setLastCheckedIndex(idx);
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    checked={searchedTableListChecked[idx]}
                                    onChange={e => {
                                      setSearchedTableListChecked(prev => {
                                        if (lastCheckedIndex > -1 && e.shiftKey) {
                                          let start =
                                            lastCheckedIndex < idx ? lastCheckedIndex : idx;
                                          let end = lastCheckedIndex < idx ? idx : lastCheckedIndex;
                                          for (let i = start; i <= end; i++) {
                                            prev[i] = e.target.checked;
                                          }
                                        } else prev[idx] = e.target.checked;
                                        return [...prev];
                                      });
                                      setLastCheckedIndex(idx);
                                    }}
                                  />
                                </td>
                                <td>{idx + 1}</td>
                                <td>{el.custId.replace(/(.{4})/, "$1-")}</td>
                                <td>{el.company}</td>
                                <td>{el.kname}</td>
                                <td>{el.position}</td>
                              </tr>
                            );
                          })
                        : Array.from({ length: tableLimit }, (_, idx) => {
                            return (
                              <tr>
                                {idx === 0 && (
                                  <td colSpan={6} rowSpan={tableLimit}>
                                    검색된 결과가 없습니다.
                                  </td>
                                )}
                              </tr>
                            );
                          })}
                      {searchedTableList?.list?.length !== 0 && (
                        <div ref={setInfinityScroll} style={{ height: "1px" }} />
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 선택된 고객 정보 */}
        <CustomerManageDetail
          customerGetDetail={customerGetDetail}
          btn_search={btn_search}
          btn_search_treeNode={btn_search_treeNode}
          isSendSMSModalOpen={isSendSMSModalOpen}
          setIsSendSMSModalOpen={setIsSendSMSModalOpen}
        />
      </div>
    </>
  );
};
