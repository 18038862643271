import { decrypt } from "./decrypt/decrypt";

/**
 * 1 - 전체 지사 및 직원 조회 권한
 * 0 - 본인 지사만 조회 권한
 * -1 - 본인만 조회 권한
 * @returns {string}
 */
export default function lockAuth() {
  const userPermit = decrypt("userInfo").userPermit;
  const authority = decrypt("userInfo").authority;

  // 전산관리자 98,99
  // 업무지원팀 51
  if (["99", "98", "51"].includes(userPermit)) {
    return "2";
  } else if (["61", "35", "36", "92"].includes(userPermit)) {
    // 본사마스터 61
    // 재경팀장 35,36
    // 기획홍보팀장 92
    return "1";
  } else if (["31"].includes(authority)) {
    // 지사장 31
    return "0";
  } else return "-1";
}
