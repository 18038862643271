import React, { useEffect, useState } from "react";
import EmpModal from "../../../../../components/modal/Modal";
import { useMutation, useQuery } from "@tanstack/react-query";
import { axios } from "../../../../../util/axios/axios";
//import { useSelectSender } from "../../../../../api/code/useSelectSender";
import { useSelectSmsBank } from "../../../../../api/code/useSelectSmsBank";
import { SendSMSPreviewModal } from "./sendSMSPreviewModal";
import { SendSMSSearchTableModal } from "./sendSMSSearchTableModal";
import { SendSMSCautionModal } from "./sendSMSCautionModal";
import moment from "moment";

function getByteSize(string) {
  let byteSize = 0;
  for (let i = 0; i < string.length; i++) {
    const charCode = string.charCodeAt(i);
    if (charCode <= 0x7f) {
      // ASCII 문자
      byteSize += 1;
    } else {
      // 한글 및 기타 특수문자
      byteSize += 2;
    }
  }
  return byteSize;
}

export const SendSMSModal = ({ isSendSMSModalOpen, setIsSendSMSModalOpen }) => {
  const [SMSCount, setSMSCount] = useState({
    max: 0,
    now: 0,
  });

  const [searchData, setSearchData] = useState({
    deny: true,
    keyword: "",
    page: 1,
  });

  const [selectedTableList, setSelectedTableList] = useState([]); // 전송대상
  const [isRefuse, setIsRefuse] = useState(false); // 수신거부 포함 여부

  const [SMSText, setSMSText] = useState(""); // SMS 내용
  const [SMSTitle, setSMSTitle] = useState(""); // SMS 제목 90바이트가 넘어가면 사용됨
  const [senderPhone, setSenderPhone] = useState(""); // 발신번호
  const [reserveTime, setReserveTime] = useState({ isActive: false, timeData: "" }); // 예약
  const [depositAccount, setDepositAccount] = useState({ chkCode: "00" });
  const [duplicateSendCheck, setDuplicateSendCheck] = useState(false); // 중복전송 제외 기능 사용
  const [duplicateRunData, setDuplicateRunData] = useState({
    date: "",
    list: "",
    page: "1",
    select: "",
  }); // 중복전송 제외 실행 데이터
  const [isDuplicateRun, setIsDuplicateRun] = useState(false); // 중복전송 제외 실행 여부

  // 미리보기 모달
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState({
    data: null,
    isOpen: false,
  });

  // 전송대상 검색결과 모달
  const [isSMSSearchTableModalOpen, setIsSMSSearchTableModalOpen] = useState({
    data: null,
    isOpen: false,
  });

  // 보내기 전 주의사항 모달
  const [isSendSMSCautionModalOpen, setIsSendSMSCautionModalOpen] = useState({
    data: null,
    isOpen: false,
  });

  //const selectSender = useSelectSender(); // 발신번호 조회
  const selectSmsBank = useSelectSmsBank(); // 입금계좌번호 조회

  // SMS 일일 할당량 조회
  const customerGetAllocCount = useQuery(
    ["getAllocCount"],
    () => axios.get("/api/work/customer/getAllocCount"),
    {
      onSuccess: res => {
        setSMSCount(res.data.result);
      },
      onError: err => {
        if (err.response.data.code === "-1") {
          setSMSCount({ max: 0, now: 0 });
        }
      },
    }
  );

  // SMS 고객 조회
  const customerSearchSmsCust = useMutation(
    data => axios.get("/api/work/customer/searchSmsCust", { params: data })
    // {
    //   onSuccess: res => {
    //     setSelectedTableList(res.data.result);
    //   },
    // }
  );

  // SMS 보내기
  const customerSendSms = useMutation(data => axios.post("/api/work/customer/sendSms", data), {
    onSuccess: res => {
      alert(res.data.msg);
      setIsSendSMSModalOpen({ data: null, isOpen: false });
    },
  });

  // 중복체크 삭제
  const customerCheckSmsDup = useMutation(
    data => axios.post("/api/work/customer/checkSmsDup", data),
    {
      onSuccess: res => {
        const newSelectedTableList = [...selectedTableList];

        res.data.result.map((el, idx) => {
          newSelectedTableList[idx].isDuplication = el;
        });

        setSelectedTableList(newSelectedTableList);
      },
    }
  );

  useEffect(() => {
    if (isSendSMSModalOpen.data) {
      setSelectedTableList(
        isSendSMSModalOpen.data.map(el => ({
          cellular: el.smsCellular || el.cellular,
          custId: el.custId,
          dup: false,
          hpCheck: el?.hpCheck === "Y" || el?.hpCheck === "" || el?.hpCheck == null ? "Y" : "N",
          hpCheck2: el?.hpCheck2 === "Y" || el?.hpCheck2 === "" || el?.hpCheck2 == null ? "Y" : "N",
          isDuplication: "N",
          kname: el?.kname,
          position: el?.position,
          ownerName: el?.ownerName,
          ownerPosition: el?.ownerPosition,
          ownerCelTel: el?.ownerCelTel,
          seqNo: el?.seqNo,
        }))
      );
    }
  }, []);

  const btn_add_name = () => {
    if (SMSText.split(" ").some(el => el === " {이름} ")) {
      setSMSText(SMSText.replace(" {이름} ", ""));
    } else {
      setSMSText(SMSText + " {이름} ");
    }
  };

  const btn_add_position = () => {
    if (SMSText.split(" ").some(el => el === " {직위} ")) {
      setSMSText(SMSText.replace(" {직위} ", ""));
    } else {
      setSMSText(SMSText + " {직위} ");
    }
  };

  const btn_unsubscribe = e => {
    setIsRefuse(e.target.checked);

    if (e.target.checked) {
      setSMSText(SMSText + "\n\r\n무료수신거부 080-863-5885");
    } else {
      setSMSText(SMSText.replace("\n\r\n무료수신거부 080-863-5885", ""));
    }
  };

  const btn_search_cust = () => {
    customerSearchSmsCust.mutateAsync(searchData).then(res => {
      // if (res.data.result.length > 1) {
      //   setIsSMSSearchTableModalOpen({ data: res.data.result, isOpen: true });
      // } else if (res.data.result.length === 0) {
      //   alert("검색결과가 없습니다.");
      // } else {
      //   setSelectedTableList(prev => [...prev, { ...res.data.result, isDuplication: "N" }]);
      // }

      if (res.data.result.length === 0) {
        alert("검색결과가 없습니다.");
      } else {
        setIsSMSSearchTableModalOpen({ data: res.data.result, isOpen: true });
      }
    });
  };

  const btn_delete = (data, idx) => {
    if (window.confirm("삭제하시겠습니까?")) {
      setSelectedTableList(prev => prev.filter((el, index) => index !== idx));
    }
  };

  const btn_sms_setting = () => {
    if (depositAccount.chkCode === "00") {
      alert("입금계좌를 선택해주세요.");
      return;
    }

    setSMSText(
      SMSText +
        `[입금계좌: ${
          selectSmsBank.data?.find(el => el.chkCode === depositAccount?.chkCode)?.codename
        }] ${selectSmsBank.data?.find(el => el.chkCode === depositAccount?.chkCode)?.payAccount} ${
          selectSmsBank.data?.find(el => el.chkCode === depositAccount?.chkCode)?.payName
        }`
    );
  };

  const btn_SMS_send = () => {
    if (duplicateSendCheck && !isDuplicateRun) {
      alert("중복전송 제외 실행을 먼저 해주세요.");
      return;
    }

    if (senderPhone === "") {
      alert("발신번호를 선택해주세요.");
      return;
    }

    // if (selectedTableList.length === 0) {
    //   alert(
    //     "문자를 전송할 대상이 추가되지 않았습니다...!!! \n\n문자전송을 원하는 고객을 추가한 후 확인하세요...?"
    //   );
    //   return;
    // }

    // if (SMSText === "") {
    //   alert("전송할 메세지를 입력한 후 전송하세요...!!!");
    //   return;
    // }

    if (reserveTime.isActive && reserveTime.timeData === "") {
      alert("예약시간을 선택해주세요.");
      return;
    }

    if (getByteSize(SMSText) > 90 && SMSTitle === "") {
      alert("제목을 입력해주세요.");
      return;
    }

    let filterList = selectedTableList.filter(el => el.isDuplication === "N");

    if (searchData.deny) {
      filterList = filterList
        .filter(el => el.hpCheck2 !== "N")
        .map(el2 => ({
          kname: el2.kname,
          position: el2.position,
          rcvPhone: el2.cellular,
          seqNo: el2.seqNo,
        }));
    } else {
      filterList = filterList.map(el2 => ({
        kname: el2.kname,
        position: el2.position,
        rcvPhone: el2.cellular,
        seqNo: el2.seqNo,
      }));
    }

    if (filterList.length === 0) {
      alert("전송가능한 대상이 없습니다.");
      return;
    }

    // customerSendSms.mutate({
    //   list: filterList.map(el => ({
    //     kname: el.kname,
    //     position: el.position,
    //     rcvPhone: el.rcvPhone,
    //     seqNo: el.seqNo,
    //   })),
    //   sendDate: null,
    //   //     senderPhone: selectSender.data.find(el2 => el2.seq === +senderPhone).sendPhoneNo, <- 삭제
    //   senderPhone: senderPhone,
    //   text: SMSText,
    // });

    setIsSendSMSCautionModalOpen({
      data: {
        filterList: filterList,
        SMSText: SMSText,
        senderPhone: senderPhone,
        title: SMSTitle,
        sendDate: reserveTime.timeData
          ? moment(reserveTime.timeData).format("YYYY-MM-DD HH:mm")
          : null,
      },
      isOpen: true,
    });
  };

  const btn_duplicate_run = () => {
    setIsDuplicateRun(true);

    customerCheckSmsDup.mutate({
      date: duplicateRunData.date,
      list: selectedTableList.map(el => el.cellular),
      page: 1,
      select: duplicateRunData.select,
    });
  };

  const pressEnter = e => {
    if (e.key === "Enter") {
      btn_search_cust();
    }
  };

  return (
    <>
      {isPreviewModalOpen.isOpen && (
        <SendSMSPreviewModal
          isPreviewModalOpen={isPreviewModalOpen}
          setIsPreviewModalOpen={setIsPreviewModalOpen}
        />
      )}

      {isSMSSearchTableModalOpen.isOpen && (
        <SendSMSSearchTableModal
          isSMSSearchTableModalOpen={isSMSSearchTableModalOpen}
          setIsSMSSearchTableModalOpen={setIsSMSSearchTableModalOpen}
          selectedTableList={selectedTableList}
          setSelectedTableList={setSelectedTableList}
          searchData={searchData}
          setSearchData={setSearchData}
        />
      )}

      {isSendSMSCautionModalOpen.isOpen && (
        <SendSMSCautionModal
          isSendSMSCautionModalOpen={isSendSMSCautionModalOpen}
          setIsSendSMSCautionModalOpen={setIsSendSMSCautionModalOpen}
          isSendSMSModalOpen={isSendSMSModalOpen}
          setIsSendSMSModalOpen={setIsSendSMSModalOpen}
          searchData={searchData}
        />
      )}

      <EmpModal
        show={isSendSMSModalOpen.isOpen}
        width="100%"
        strClass="smsModal"
        title="문자메세지 전송"
        onHide={() => setIsSendSMSModalOpen({ data: null, isOpen: false })}
        size="lg"
        header_buttons={
          <button
            id="btnSmsPreview"
            className="btn btn-outline-primary"
            style={{ marginLeft: "222px" }}
            onClick={() => {
              let filter = [...selectedTableList];

              if (searchData.deny) {
                filter = filter.filter(el => el.hpCheck2 !== "N");
              }

              if (filter.length === 0) {
                alert("전송대상을 선택해주세요.");
                return;
              }

              setIsPreviewModalOpen({
                data: { SMSText: SMSText, selectedTableList: filter },
                isOpen: true,
              });
            }}
            disabled={SMSCount?.max === 0}
          >
            미리보기
          </button>
        }
      >
        <div className="row gx-3">
          <div className="col-5">
            <div className="tab1-row1 mb-3">
              <div className="d-flex align-items-center">
                <div className="tab1-row1-count">
                  일일 할당량 <span className="text-primary">{SMSCount?.max}</span> /전송량{" "}
                  <span className="text-primary">{SMSCount?.now}</span>
                </div>
              </div>
            </div>
            <div className="smsOuter">
              <div className="smsTopSection">
                <label className="form-label m-0 text-nowrap" htmlFor="title">
                  제목
                </label>
                <input
                  className="form-control "
                  type="text"
                  id="title"
                  style={{ marginLeft: 3 }}
                  disabled={getByteSize(SMSText) <= 90}
                  onChange={e => setSMSTitle(e.target.value)}
                />
              </div>
              <div className="smsTopSection">
                <label className="form-label m-0">변수</label>
                <div className="smsBluebox cursor-pointer" onClick={btn_add_name}>
                  {"{이름}"}
                </div>
                <div className="smsGreenbox cursor-pointer" onClick={btn_add_position}>
                  {"{직위}"}
                </div>
              </div>
              <div className="smsContent">
                <textarea
                  className="form-control"
                  rows="8"
                  value={SMSText}
                  onChange={e => {
                    setSMSText(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="smsBtmRow">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="flexCheckDefault"
                  checked={isRefuse}
                  onChange={e => btn_unsubscribe(e)}

                  // onChange={e => {
                  //   setIsRefuse(e.target.checked);
                  //
                  //   if (e.target.checked) {
                  //     setSMSText(SMSText + "\n\r\n무료수신거부 080-863-5885");
                  //   } else {
                  //     setSMSText(SMSText.replace("\n\r\n무료수신거부 080-863-5885", ""));
                  //   }
                  // }}
                />
                <label className="form-check-label f-regular" htmlFor="flexCheckDefault">
                  수신거부포함
                </label>
              </div>
              <div className="smsRedbox">080-863-5885</div>
              <div className="form-check-label f-regular d-flex ms-auto">
                {getByteSize(SMSText)} / 90 bytes
              </div>
            </div>

            <div className="smsBtmRow">
              <label className="form-label m-0 me-2">발신번호</label>
              <select
                className="form-select w-120px me-2"
                value={senderPhone}
                onChange={e => {
                  setSenderPhone(e.target.value);
                }}
              >
                <option value="" disabled={true} selected={true}>
                  발신번호를 선택해주세요.
                </option>
                <option value="02-538-1666">본사</option>
                <option value="042-476-1666">대전</option>
                <option value="053-767-1666">대구</option>
                <option value="051-464-1666">부산</option>
                <option value="051-715-9199">부산다골프</option>
                <option value="062-716-8888">호남(062)</option>
                <option value="061-803-1234">호남(061)</option>
                {/*{selectSender.data?.map(el => (
                  <option value={el.seq}>{el.sendPhoneNo}</option>
                ))}*/}
              </select>
              {/*<button className="btn btn-outline-primary me-2">선택</button>*/}
              <button className="btn btn-primary" onClick={btn_SMS_send}>
                보내기
              </button>
            </div>
          </div>
          <div className="col-7">
            <div className="smsTopSection border-0 h-unset mb-10px">
              <input
                type="checkbox"
                checked={reserveTime.isActive}
                onChange={e => {
                  if (e.target.checked) {
                    setReserveTime({ ...reserveTime, isActive: true });
                  } else {
                    setReserveTime({ ...reserveTime, isActive: false, timeData: "" });
                  }
                }}
              />
              <label className="form-label m-0 me-2">예약</label>
              <input
                className="form-control w-200 me-2"
                type="datetime-local"
                id=""
                name=""
                value={reserveTime.timeData}
                onChange={e => {
                  setReserveTime({ ...reserveTime, timeData: e.target.value });
                }}
                max="9999-12-31T23:59"
                disabled={!reserveTime.isActive}
              />
              {/*<button className="btn btn-primary">확인</button>*/}
            </div>
            <div className="smsOuter">
              <div className="smsTopSection justify-content-between">
                <label className="form-label m-0 me-3">전송대상</label>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexCheckDefault1"
                    checked={searchData.deny}
                    onChange={e => {
                      setSearchData({ ...searchData, deny: e.target.checked });
                    }}
                  />
                  <label className="form-check-label f-regular" htmlFor="flexCheckDefault1">
                    광고문자 거부자 제외
                  </label>
                </div>
              </div>
              <div className="smsContent">
                <div className="smsTopSection border-0 h-unset mb-10px p-0" onKeyDown={pressEnter}>
                  <label className="form-label m-0 me-2">이름 또는 번호</label>
                  <input
                    className="form-control w-120px me-2"
                    type="text"
                    id=""
                    name=""
                    value={searchData.keyword}
                    onChange={e => {
                      setSearchData({ ...searchData, keyword: e.target.value });
                    }}
                  />
                  <button className="btn btn-primary" onClick={btn_search_cust}>
                    검색
                  </button>
                </div>
                <div className="table-container table-sticky h-333">
                  <table className="table table-bordered table-responsive my-table m-0">
                    <thead>
                      <tr>
                        <th>No.</th>
                        <th>성명</th>
                        <th>직위</th>
                        <th>문자</th>
                        <th>광고</th>
                        <th>번호</th>
                        <th>삭제</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedTableList.length !== 0 ? (
                        <>
                          {selectedTableList.map((el, idx) => (
                            <tr
                              key={el.seqNo}
                              className={`${el.isDuplication === "Y" ? "bg-lightblue" : ""} ${
                                searchData.deny && el.hpCheck2 === "N" ? "bg-red" : ""
                              }`}
                            >
                              <td>{idx + 1}</td>
                              <td>{el.kname}</td>
                              <td>{el.position}</td>
                              <td>{el.hpCheck}</td>
                              <td>{el.hpCheck2}</td>
                              <td>{el.cellular}</td>
                              <td className="cursor-pointer" onClick={() => btn_delete(el, idx)}>
                                <img src={process.env.PUBLIC_URL + "/images/delete.png"} alt="" />
                              </td>
                            </tr>
                          ))}
                        </>
                      ) : (
                        <tr>
                          <td colSpan={7}>선택된 전송대상이 없습니다.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="smsBtmRow justify-content-between">
              <label className="form-label m-0 me-2">입금계좌 선택</label>
              <button className="btn btn-outline-primary" onClick={btn_sms_setting}>
                문자셋팅
              </button>
            </div>

            <div className="smsBtmRow justify-content-between">
              <select
                className="form-select w-200 me-2"
                value={depositAccount.chkCode}
                onChange={e => {
                  setDepositAccount({ chkCode: e.target.value });
                }}
              >
                <option value="00" disabled={true} selected={true}>
                  입금계좌 선택
                </option>
                {selectSmsBank.data?.map(el => (
                  <option value={el.chkCode}>{el.chkName}</option>
                ))}
              </select>
              <div className="smsGreybox w-200 me-2 px-2">
                {depositAccount?.chkCode !== "00"
                  ? selectSmsBank.data?.find(el => el.chkCode === depositAccount?.chkCode)
                      ?.payAccount
                  : "계좌번호"}
              </div>
              <div className="smsGreybox w-120px px-2">
                {depositAccount?.chkCode !== "00"
                  ? selectSmsBank.data?.find(el => el.chkCode === depositAccount?.chkCode)?.payName
                  : "예금주"}
              </div>
            </div>

            <div className="smsBtmRow">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={duplicateSendCheck}
                  onChange={e => {
                    setDuplicateSendCheck(e.target.checked);

                    if (!e.target.checked) {
                      setDuplicateRunData({
                        date: "",
                        list: "",
                        page: "1",
                        select: "",
                      });
                    }
                  }}
                  id="flexCheckDefault2"
                />
                <label className="form-check-label f-regular" htmlFor="flexCheckDefault2">
                  중복전송 제외 기능 사용
                </label>
              </div>
            </div>

            <div className="smsOuter footer px-2 pb-2">
              <div className="smsBtmRow justify-content-between">
                <div className="d-flex">
                  <label className="form-label m-0 me-2">중복체크범위</label>
                  <select
                    className="form-select w-200 me-2"
                    value={duplicateRunData.select}
                    onChange={e => {
                      setDuplicateRunData({ ...duplicateRunData, select: e.target.value });
                    }}
                  >
                    <option value="">중복체크범위 선택</option>
                    <option value="1">본인 전송건</option>
                    <option value="2">지사 전송건</option>
                    <option value="3">전체 전송건</option>
                  </select>
                </div>

                <div className="d-flex">
                  <label className="form-label m-0 me-2">기준일자</label>
                  <input
                    className="form-control w-200"
                    type="date"
                    value={duplicateRunData.date}
                    onChange={e => {
                      setDuplicateRunData({ ...duplicateRunData, date: e.target.value });
                    }}
                    max="9999-12-31"
                    onBlur={e => {
                      if (duplicateSendCheck)
                        if (!moment().subtract(31, "days").isBefore(e.target.value)) {
                          alert("1개월 이내의 날짜를 선택해주세요.");
                          e.target.value = moment().format("YYYY-MM-DD");
                        }
                    }}
                  />
                </div>
              </div>
              <div className="smsBtmRow f-regular justify-content-end form-text text-muted">
                (일자 이후에 전송 기록이 있는 고객은 제외)
              </div>

              <div className="smsBtmRow justify-content-end">
                <label className="form-label m-0 me-2">
                  제외건수{" "}
                  <span className="text-primary">
                    {selectedTableList.filter(el => el.isDuplication === "Y").length}
                  </span>{" "}
                  건
                </label>
                <button
                  className="btn btn-primary"
                  onClick={btn_duplicate_run}
                  disabled={!duplicateSendCheck}
                >
                  실행
                </button>
              </div>
            </div>
          </div>
        </div>
      </EmpModal>
    </>
  );
};
