import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../util/axios/axios";
import { useAtom } from "jotai/index";
import {
  orderManagementStatus_medoTable_atom,
  orderManagementStatus_mesuTable_atom,
  orderManagementStatus_searchData_atom,
  orderManagementStatus_searchData_atom_init,
} from "../../../data/atom/goodsManage/orderManagementStatus/orderManagementStatus_atom";
import { useGetJisaGubun } from "../../../api/code/useGetJisaGubun";
import React, { useEffect, useRef, useState } from "react";
import { useGetOrderManageStatusList } from "../../../api/code/useGetOrderManageStatusList";
import { useVirtualizer } from "@tanstack/react-virtual";
import { useNavigate } from "react-router-dom";
import { useSidebarOnClickEvent } from "../../../util/sidebarOnClickEvent/sidebarOnClickEvent";
import { decrypt } from "../../../util/decrypt/decrypt";
import useRecentTabEvent from "../../../components/layout/useRecentTabEvent";

export const OrderManagementStatus = () => {
  const navigate = useNavigate();
  const defaultURL = process.env.PUBLIC_URL;
  const { sidebarOnClickEvent } = useSidebarOnClickEvent();

  const medoTargetRef = useRef();
  const mesuTargetRef = useRef();

  const [searchData, setSearchData] = useAtom(orderManagementStatus_searchData_atom);
  const [medoTable, setMedoTable] = useAtom(orderManagementStatus_medoTable_atom);
  const [mesuTable, setMesuTable] = useAtom(orderManagementStatus_mesuTable_atom);

  const [medoModifyList, setMedoModifyList] = useState([]);
  const [mesuModifyList, setMesuModifyList] = useState([]);

  const [medoBatchCancel, setMedoBatchCancel] = useState([]);
  const [mesuBatchCancel, setMesuBatchCancel] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState({ data: null, isOpen: false });

  const getJisaGubun = useGetJisaGubun();
  const getOrderManageStatusList = useGetOrderManageStatusList();
  const { addTabClosedEvent } = useRecentTabEvent();

  // 주문관리 매도 현황 검색
  const orderManageStatusSearch1 = useMutation(
    data => axios.get("/api/schedule/orderManageStatus/search1", { params: data }),
    {
      onSuccess: res => {
        setMedoTable(res.data.result);
        setMedoModifyList(Array.from({ length: res.data.result.length }, () => false));
        setMedoBatchCancel(Array.from({ length: res.data.result.length }, () => false));
      },
    }
  );

  // 주문관리 매수 현황 검색
  const orderManageStatusSearch2 = useMutation(
    data => axios.get("/api/schedule/orderManageStatus/search2", { params: data }),
    {
      onSuccess: res => {
        setMesuTable(res.data.result);
        setMesuModifyList(Array.from({ length: res.data.result.length }, () => false));
        setMesuBatchCancel(Array.from({ length: res.data.result.length }, () => false));
      },
    }
  );

  // 주문관리 현황 상태값 일괄취소 처리 버튼
  const orderManageStatusSaveButton = useMutation(
    data => axios.post("/api/schedule/orderManageStatus/saveButton", data),
    {
      onSuccess: res => {
        orderManageStatusSearch2.mutate(searchData);
        orderManageStatusSearch1.mutate(searchData);
      },
    }
  );

  // 주문관리 현황 저장/수정
  const orderManageStatusSave = useMutation(
    data => axios.post("/api/schedule/orderManageStatus/save", data),
    {
      onSuccess: res => {},
    }
  );

  useEffect(() => {
    const interval = setInterval(
      () => {
        orderManageStatusSearch2.mutate(searchData);
        orderManageStatusSearch1.mutate(searchData);
      },
      1000 * 60 * 5
    );

    addTabClosedEvent(location.pathname, () => {
      delete window.OrderManagementStatus;
      setSearchData({ ...orderManagementStatus_searchData_atom_init });
      setMedoTable([]);
      setMesuTable([]);
    });

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (getJisaGubun.data && !window.OrderManagementStatus) {
      window.OrderManagementStatus = true;
      setSearchData(prev => {
        return {
          ...prev,
          jisaGubn:
            decrypt("userInfo").userPermit === "61" ||
            decrypt("userInfo").userPermit === "99" ||
            decrypt("userInfo").userPermit === "98" ||
            decrypt("userInfo").userPermit === "35" ||
            decrypt("userInfo").userPermit === "92" ||
            decrypt("userInfo").userPermit === "93"
              ? "99"
              : decrypt("userInfo").jisa,
        };
      });
    }
  }, [getJisaGubun.data]);

  const btn_search = () => {
    orderManageStatusSearch2.mutate(searchData);
    orderManageStatusSearch1.mutate(searchData);
  };

  const btn_batch_cancel = () => {
    if (window.confirm("상태값을 일괄취소하시겠습니까?")) {
      if (medoBatchCancel.every(el => el === false) && mesuBatchCancel.every(el => el === false)) {
        alert("취소할 데이터가 없습니다.");
        return;
      }

      const medoFilter = medoTable
        .filter((el, index) => medoBatchCancel[index])
        .map(el2 => ({
          ...el2,
          checkBox: "Y",
          page: 1,
        }));
      const mesuFilter = mesuTable
        .filter((el, index) => mesuBatchCancel[index])
        .map(el2 => ({
          ...el2,
          checkBox: "Y",
          page: 1,
        }));

      orderManageStatusSaveButton.mutate([...medoFilter, ...mesuFilter]);
    }
  };

  const btn_medo_register = () => {
    if (window.confirm("매도를 수정하시겠습니까?")) {
      const filter = medoTable.filter((el, index) => medoModifyList[index]);

      if (filter.length === 0) {
        alert("수정된 데이터가 없습니다.");
        return;
      }

      orderManageStatusSave.mutate(
        filter.map(el2 => ({
          checkBox: "Y",
          completeKind: el2.completeKind,
          counselorId: el2.counselorId,
          custId: el2.custId,
          ilNo: el2.ilNo,
          memberNo: el2.memberNo,
          // modDate: ,
          page: 1,
          processCheck: el2.processCheck,
          quotationWon: el2.quotationWon,
          remark: el2.remark,
          requestDate: el2.requestDate,
          requireWon: el2.requireWon,
        }))
      );
    }
  };

  const btn_medo_cancel = () => {
    if (window.confirm("매도 수정을 취소하시겠습니까?")) {
      orderManageStatusSearch2.mutate(searchData);
    }
  };

  const btn_mesu_register = () => {
    if (window.confirm("매수를 수정하시겠습니까?")) {
      const filter = mesuTable.filter((el, index) => mesuModifyList[index]);

      if (filter.length === 0) {
        alert("수정된 데이터가 없습니다.");
        return;
      }

      orderManageStatusSave.mutate(
        filter.map(el2 => ({
          checkBox: "Y",
          completeKind: el2.completeKind,
          counselorId: el2.counselorId,
          custId: el2.custId,
          ilNo: el2.ilNo,
          memberNo: el2.memberNo,
          // modDate: ,
          page: 1,
          processCheck: el2.processCheck,
          quotationWon: el2.quotationWon,
          remark: el2.remark,
          requestDate: el2.requestDate,
          requireWon: el2.requireWon,
        }))
      );
    }
  };

  const btn_mesu_cancel = () => {
    if (window.confirm("매수 수정을 취소하시겠습니까?")) {
      orderManageStatusSearch1.mutate(searchData);
    }
  };

  const pressEnter = e => {
    if (e.key === "Enter") {
      btn_search();
    }
  };

  const medoRowVirtualizer = useVirtualizer({
    count: medoTable.length,
    getScrollElement: () => medoTargetRef.current,
    estimateSize: () => 30,
  });

  const mesuRowVirtualizer = useVirtualizer({
    count: mesuTable.length,
    getScrollElement: () => mesuTargetRef.current,
    estimateSize: () => 30,
  });

  const statusColor = status => {
    switch (status) {
      case "12":
      case "13":
        return "blue-tr";
      case "14":
      case "15":
        return "yellow-tr";
      case "22":
        return "grey-tr";
      case "33":
        return "darkgrey-tr";
      case "55":
        return "lightred-tr";
      case "44":
        return "orange-tr";
      case "34":
        return "green-tr";
      default:
        return "";
    }
  };

  return (
    <>
      <div className="content-inner">
        <div className="filter1_container">
          <div className="filter_header_row">
            <div className="filter-title">주문현황 검색</div>
            <div className="filter-buttons"></div>
          </div>
          <div className="filter_fields_container" onKeyDown={pressEnter}>
            <div className="filter_fields_row">
              <div className="d-flex align-items-center justify-content-between">
                <div className="filter1-field filter1-field1">
                  <label className="filter1-label">지사</label>
                  <select
                    className="form-select filter1-select1"
                    value={searchData.jisaGubn}
                    onChange={e => {
                      setSearchData({ ...searchData, jisaGubn: e.target.value });
                    }}
                    disabled={
                      decrypt("userInfo").userPermit !== "61" &&
                      decrypt("userInfo").userPermit !== "99" &&
                      decrypt("userInfo").userPermit !== "98" &&
                      decrypt("userInfo").userPermit !== "35" &&
                      decrypt("userInfo").userPermit !== "92" &&
                      decrypt("userInfo").userPermit !== "93"
                    }
                  >
                    {getJisaGubun.data?.map(el => (
                      <option value={el.key}>{el.value}</option>
                    ))}
                  </select>
                </div>
                <div className="filter1-field filter1-field1">
                  <label className="filter1-label">종목</label>
                  <select
                    className="form-select filter1-select1"
                    value={searchData.gubun3}
                    onChange={e => {
                      setSearchData({ ...searchData, gubun3: e.target.value });
                    }}
                  >
                    <option value="0">전체</option>
                    <option value="1">골프</option>
                    <option value="2">콘도</option>
                    <option value="3">헬스</option>
                  </select>
                </div>
                {decrypt("userInfo").userPermit !== "44" &&
                  decrypt("userInfo").userPermit !== "88" &&
                  decrypt("userInfo").userPermit !== "71" && (
                    <div className="filter1-field filter1-field1 ms-5">
                      <label className="filter1-label">상담자</label>
                      <input
                        type="text"
                        className="form-control filter-input filter1-input2"
                        value={searchData.userName}
                        onChange={e => {
                          setSearchData({ ...searchData, userName: e.target.value });
                        }}
                      />
                    </div>
                  )}
                <div className="filter1-field filter1-field1 ms-3">
                  <label className="filter1-label">회원권</label>
                  <input
                    type="text"
                    className="form-control filter-input filter1-input2"
                    value={searchData.member}
                    onChange={e => {
                      setSearchData({ ...searchData, member: e.target.value });
                    }}
                  />
                </div>
                <div className="filter1-field filter1-field2 m-0 p-0">
                  <label className="filter1-label ms-3">매물기간</label>
                  <input
                    type="date"
                    className="form-control filter-input filter1-input2"
                    value={searchData.frontDate}
                    onChange={e => {
                      setSearchData({ ...searchData, frontDate: e.target.value });
                    }}
                    max="9999-12-31"
                  />
                </div>
                <div className="txt_daterange">~</div>
                <div className="filter1-field filter1-field3 px-0">
                  <input
                    type="date"
                    className="form-control filter-input filter1-input2"
                    value={searchData.backDate}
                    onChange={e => {
                      setSearchData({ ...searchData, backDate: e.target.value });
                    }}
                    max="9999-12-31"
                  />
                </div>
              </div>
            </div>
            <div className="filter_fields_row mt-2">
              <div className="d-flex align-items-center justify-content-start">
                <div className="filter1-field filter1-field1">
                  <label className="filter1-label">상태</label>
                  <select
                    className="form-select filter1-select1"
                    value={searchData.statusFlag}
                    onChange={e => {
                      setSearchData({ ...searchData, statusFlag: e.target.value });
                    }}
                  >
                    {/*<option value="0">전체</option>*/}
                    {/*{getOrderManageStatusList.data?.map(el => (*/}
                    {/*  <option value={el.key}>{el.value}</option>*/}
                    {/*))}*/}
                    <option value="0">기본(홀딩포함)</option>
                    <option value="1">보류 포함</option>
                    <option value="2">취소,종료건만</option>
                    <option value="3">개서中</option>
                    <option value="9">전체</option>
                  </select>
                </div>
                <div className="filter1-field filter1-field1 ms-1">
                  <label className="filter1-label">입금완료 유무</label>
                  <select
                    className="form-select filter1-select1"
                    value={searchData.completeKind}
                    onChange={e => {
                      setSearchData({ ...searchData, completeKind: e.target.value });
                    }}
                  >
                    <option value="0">전체</option>
                    <option value="1">입금 완료된 매도</option>
                    <option value="2">입금 미완료 매도</option>
                    <option value="3">미선택</option>
                  </select>
                </div>
                <div className="filter1-field filter1-field1">
                  <label className="filter1-label">매물종류</label>
                  <select
                    className="form-select filter1-select1"
                    value={searchData.suppleKind}
                    onChange={e => {
                      setSearchData({ ...searchData, suppleKind: e.target.value });
                    }}
                  >
                    {decrypt("userInfo").userPermit === "61" ||
                    decrypt("userInfo").userPermit === "99" ||
                    decrypt("userInfo").userPermit === "98" ||
                    decrypt("userInfo").userPermit === "35" ||
                    decrypt("userInfo").userPermit === "92" ||
                    decrypt("userInfo").userPermit === "93" ? (
                      <>
                        <option value="1">전체매물</option>
                        <option value="2">팀원매물</option>
                        <option value="3">본인매물</option>
                      </>
                    ) : decrypt("userInfo").userPermit === "44" ||
                      decrypt("userInfo").userPermit === "88" ||
                      decrypt("userInfo").userPermit === "71" ? (
                      decrypt("userInfo").job === "74" ||
                      decrypt("userInfo").job === "78" ||
                      decrypt("userInfo").job === "79" ||
                      decrypt("userInfo").job === "80" ||
                      decrypt("userInfo").job === "83" ? (
                        <>
                          <option value="2">팀원매물</option>
                          <option value="3">본인매물</option>
                        </>
                      ) : decrypt("userInfo").job === "81" ? (
                        <>
                          <option value="1">전체매물</option>
                          <option value="2">팀원매물</option>
                          <option value="3">본인매물</option>
                        </>
                      ) : (
                        <>
                          <option value="3">본인매물</option>
                        </>
                      )
                    ) : (
                      <option value="3">본인매물</option>
                    )}
                    {/*<option value="1">전체매물</option>*/}
                    {/*<option value="2">팀원매물</option>*/}
                    {/*<option value="3">본인매물</option>*/}
                  </select>
                </div>
              </div>
              <div className="d-flex">
                <button
                  className="btn btn-outline-primary btn-filter-outline-primary"
                  onClick={btn_batch_cancel}
                >
                  상태값 일괄 취소처리
                </button>
                <button
                  className="btn btn-outline-primary btn-filter-outline-primary me-20px"
                  onClick={btn_search}
                >
                  <img
                    className="me-2"
                    src={process.env.PUBLIC_URL + "/images/search_icon.png"}
                    alt=""
                  />
                  검색
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="row gx-3">
          <div className="col-6">
            <div className="card card-main m-0">
              <div className="card-body">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="tab1-row1-count">
                    매도주문 : &nbsp; &nbsp; &nbsp; &nbsp; {medoTable.length} &nbsp;건 수
                  </div>

                  <div className="d-flex align-items-center w-50 justify-content-end">
                    <button
                      className="btn btn-outline-primary btn-filter-outline-primary me-10"
                      onClick={btn_medo_register}
                    >
                      저장
                    </button>
                    <button
                      className="btn btn-outline-primary btn-filter-outline-primary m-0"
                      onClick={btn_medo_cancel}
                    >
                      취소
                    </button>
                  </div>
                </div>

                <div
                  className="overflow-auto table-container table-sticky h-528 mt-10px"
                  ref={medoTargetRef}
                >
                  <table
                    className="table table-bordered table-responsive my-table"
                    style={{
                      width: `${medoTable.length !== 0 ? "1500px" : ""}`,
                      marginTop: `${(medoRowVirtualizer.range?.startIndex ?? 0) * 30}px`,
                      marginBottom: `${
                        medoRowVirtualizer.getTotalSize() -
                        (medoRowVirtualizer.range?.startIndex ?? 0) * 30 -
                        medoRowVirtualizer.getVirtualItems().length * 30
                      }px`,
                    }}
                  >
                    <thead>
                      <tr>
                        <th style={{ width: "1%" }} onDoubleClick={e => e.stopPropagation()}>
                          <input
                            type="checkbox"
                            checked={
                              medoBatchCancel.length !== 0 &&
                              medoBatchCancel.every(el => el === true)
                            }
                            onChange={e => {
                              setMedoBatchCancel(medoBatchCancel.map(() => e.target.checked));
                            }}
                          />
                        </th>
                        <th style={{ width: "3%" }}>연번</th>
                        <th style={{ width: "2%" }}>종목</th>
                        <th style={{ width: "5%" }}>상담자</th>
                        <th style={{ width: "10%" }}>회원권명</th>
                        <th style={{ width: "5%" }}>희망가</th>
                        <th style={{ width: "5%" }}>제시가</th>
                        <th style={{ width: "3%" }}>상태</th>
                        <th style={{ width: "5%" }}>입금 완료 유무</th>
                        <th style={{ width: "10%" }}>비고</th>
                        <th style={{ width: "5%" }}>주문일</th>
                        <th style={{ width: "5%" }}>최종수정일시</th>
                        <th style={{ width: "5%" }}>최초등록일시</th>
                      </tr>
                    </thead>
                    <tbody>
                      {medoTable.length !== 0 ? (
                        medoRowVirtualizer.getVirtualItems().map((virtualRow, index) => (
                          <tr
                            className={`cursor-pointer ${statusColor(medoTable[virtualRow.index].processCheck)}`}
                            onDoubleClick={() => {
                              const userInfo = decrypt("userInfo");
                              if (
                                userInfo.userPermit === "99" ||
                                userInfo.loginId === medoTable[virtualRow.index].counselorId
                              )
                                sidebarOnClickEvent({
                                  name: "고객관리",
                                  url: "/work/customer/customerManage",
                                  seqNo: medoTable[virtualRow.index].seqNo,
                                  custId: medoTable[virtualRow.index].custId,
                                  kname: medoTable[virtualRow.index].kname,
                                  company: medoTable[virtualRow.index].company,
                                });
                            }}
                          >
                            <td
                              onClick={() => {
                                const newMedoBatchCancel = [...medoBatchCancel];
                                newMedoBatchCancel[virtualRow.index] =
                                  !newMedoBatchCancel[virtualRow.index];
                                setMedoBatchCancel(newMedoBatchCancel);
                              }}
                              onDoubleClick={e => e.stopPropagation()}
                            >
                              <input
                                type="checkbox"
                                checked={medoBatchCancel[virtualRow.index]}
                                onChange={e => {
                                  const newMedoBatchCancel = [...medoBatchCancel];
                                  newMedoBatchCancel[virtualRow.index] = e.target.checked;
                                  setMedoBatchCancel(newMedoBatchCancel);
                                }}
                              />
                            </td>
                            <td>{virtualRow.index + 1}</td>
                            <td>{medoTable[virtualRow.index].gubun3}</td>
                            <td>{medoTable[virtualRow.index].userName}</td>
                            <td>{medoTable[virtualRow.index].codename}</td>
                            <td>
                              <input
                                type="text"
                                className="form-control text-end"
                                value={medoTable[virtualRow.index].requireWon.toLocaleString()}
                                onChange={e => {
                                  const newMedoTable = [...medoTable];
                                  newMedoTable[virtualRow.index].requireWon = +e.target.value
                                    .replace(/,/g, "")
                                    .replace(/[^0-9]/g, "");
                                  setMedoTable(newMedoTable);

                                  const newMedoModifyList = [...medoModifyList];
                                  newMedoModifyList[virtualRow.index] = true;
                                  setMedoModifyList(newMedoModifyList);
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control text-end"
                                value={medoTable[virtualRow.index].quotationWon.toLocaleString()}
                                onChange={e => {
                                  const newMedoTable = [...medoTable];
                                  newMedoTable[virtualRow.index].quotationWon = +e.target.value
                                    .replace(/,/g, "")
                                    .replace(/[^0-9]/g, "");
                                  setMedoTable(newMedoTable);

                                  const newMedoModifyList = [...medoModifyList];
                                  newMedoModifyList[virtualRow.index] = true;
                                  setMedoModifyList(newMedoModifyList);
                                }}
                              />
                            </td>
                            <td>
                              <select
                                className="form-select"
                                value={medoTable[virtualRow.index].processCheck}
                                onChange={e => {
                                  const newMedoTable = [...medoTable];
                                  newMedoTable[virtualRow.index].processCheck = e.target.value;
                                  setMedoTable(newMedoTable);

                                  const newMedoModifyList = [...medoModifyList];
                                  newMedoModifyList[virtualRow.index] = true;
                                  setMedoModifyList(newMedoModifyList);
                                }}
                              >
                                {getOrderManageStatusList.data?.map(el => (
                                  <option value={el.key}>{el.value}</option>
                                ))}
                              </select>
                            </td>
                            <td>
                              <select
                                className="form-select"
                                value={medoTable[virtualRow.index].completeKind}
                                onChange={e => {
                                  const newMedoTable = [...medoTable];
                                  newMedoTable[virtualRow.index].completeKind = e.target.value;
                                  setMedoTable(newMedoTable);

                                  const newMedoModifyList = [...medoModifyList];
                                  newMedoModifyList[virtualRow.index] = true;
                                  setMedoModifyList(newMedoModifyList);
                                }}
                              >
                                <option value="0">전체</option>
                                <option value="1">입금 완료된 매도</option>
                                <option value="2">입금 미완료 매도</option>
                                <option value="3">미선택</option>
                              </select>
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                value={medoTable[virtualRow.index].remark}
                                onChange={e => {
                                  const newMedoTable = [...medoTable];
                                  newMedoTable[virtualRow.index].remark = e.target.value;
                                  setMedoTable(newMedoTable);

                                  const newMedoModifyList = [...medoModifyList];
                                  newMedoModifyList[virtualRow.index] = true;
                                  setMedoModifyList(newMedoModifyList);
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="date"
                                className="form-control"
                                value={medoTable[virtualRow.index].requestDate}
                                onChange={e => {
                                  const newMedoTable = [...medoTable];
                                  newMedoTable[virtualRow.index].requestDate = e.target.value;
                                  setMedoTable(newMedoTable);
                                }}
                                max="9999-12-31"
                              />
                            </td>
                            <td>{medoTable[virtualRow.index].modDate}</td>
                            <td>{medoTable[virtualRow.index].inputDate}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={13}>검색결과가 없습니다.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="col-6">
            <div className="card card-main m-0">
              <div className="card-body">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="tab1-row1-count">
                    매수주문: &nbsp; &nbsp; &nbsp; &nbsp; {mesuTable.length} &nbsp;건 수
                  </div>
                  <div className="d-flex align-items-center w-50 justify-content-end">
                    <button
                      className="btn btn-outline-primary btn-filter-outline-primary me-10"
                      onClick={btn_mesu_register}
                    >
                      저장
                    </button>
                    <button
                      className="btn btn-outline-primary btn-filter-outline-primary m-0"
                      onClick={btn_mesu_cancel}
                    >
                      취소
                    </button>
                  </div>
                </div>

                <div
                  className="overflow-auto table-container table-sticky h-528 mt-10px"
                  ref={mesuTargetRef}
                >
                  <table
                    className="table table-bordered table-responsive my-table"
                    style={{
                      width: `${mesuTable.length !== 0 ? "1500px" : ""}`,
                      marginTop: `${(mesuRowVirtualizer.range?.startIndex ?? 0) * 30}px`,
                      marginBottom: `${
                        mesuRowVirtualizer.getTotalSize() -
                        (mesuRowVirtualizer.range?.startIndex ?? 0) * 30 -
                        mesuRowVirtualizer.getVirtualItems().length * 30
                      }px`,
                    }}
                  >
                    <thead>
                      <tr>
                        <th style={{ width: "1%" }} onDoubleClick={e => e.stopPropagation()}>
                          <input
                            type="checkbox"
                            checked={
                              mesuBatchCancel.length !== 0 &&
                              mesuBatchCancel.every(el => el === true)
                            }
                            onChange={e => {
                              setMesuBatchCancel(mesuBatchCancel.map(() => e.target.checked));
                            }}
                          />
                        </th>
                        <th style={{ width: "3%" }}>연번</th>
                        <th style={{ width: "2%" }}>종목</th>
                        <th style={{ width: "5%" }}>상담자</th>
                        <th style={{ width: "10%" }}>회원권명</th>
                        <th style={{ width: "5%" }}>희망가</th>
                        <th style={{ width: "5%" }}>제시가</th>
                        <th style={{ width: "3%" }}>상태</th>
                        <th style={{ width: "5%" }}>입금 완료 유무</th>
                        <th style={{ width: "10%" }}>비고</th>
                        <th style={{ width: "5%" }}>주문일</th>
                        <th style={{ width: "5%" }}>최종수정일시</th>
                        <th style={{ width: "5%" }}>최초등록일시</th>
                      </tr>
                    </thead>
                    <tbody>
                      {mesuTable.length !== 0 ? (
                        mesuRowVirtualizer.getVirtualItems().map((virtualRow, index) => (
                          <tr
                            className={`cursor-pointer ${statusColor(mesuTable[virtualRow.index].processCheck)}`}
                            onDoubleClick={() => {
                              const userInfo = decrypt("userInfo");
                              if (
                                userInfo.userPermit === "99" ||
                                userInfo.loginId === mesuTable[virtualRow.index].counselorId
                              )
                                sidebarOnClickEvent({
                                  name: "고객관리",
                                  url: "/work/customer/customerManage",
                                  seqNo: mesuTable[virtualRow.index].seqNo,
                                  custId: mesuTable[virtualRow.index].custId,
                                  kname: mesuTable[virtualRow.index].kname,
                                  company: mesuTable[virtualRow.index].company,
                                });
                            }}
                          >
                            <td
                              onClick={e => {
                                const newMesuBatchCancel = [...mesuBatchCancel];
                                newMesuBatchCancel[virtualRow.index] =
                                  !newMesuBatchCancel[virtualRow.index];
                                setMesuBatchCancel(newMesuBatchCancel);
                              }}
                              onDoubleClick={e => e.stopPropagation()}
                            >
                              <input
                                type="checkbox"
                                checked={mesuBatchCancel[virtualRow.index]}
                                onChange={e => {
                                  const newMesuBatchCancel = [...mesuBatchCancel];
                                  newMesuBatchCancel[virtualRow.index] = e.target.checked;
                                  setMesuBatchCancel(newMesuBatchCancel);
                                }}
                              />
                            </td>
                            <td>{virtualRow.index + 1}</td>
                            <td>{mesuTable[virtualRow.index].gubun3}</td>
                            <td>{mesuTable[virtualRow.index].userName}</td>
                            <td>{mesuTable[virtualRow.index].codename}</td>
                            <td>
                              <input
                                type="text"
                                className="form-control text-end"
                                value={mesuTable[virtualRow.index].requireWon.toLocaleString()}
                                onChange={e => {
                                  const newMesuTable = [...mesuTable];
                                  newMesuTable[virtualRow.index].requireWon = +e.target.value
                                    .replace(/,/g, "")
                                    .replace(/[^0-9]/g, "");
                                  setMesuTable(newMesuTable);

                                  const newMesuModifyList = [...mesuModifyList];
                                  newMesuModifyList[virtualRow.index] = true;
                                  setMesuModifyList(newMesuModifyList);
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control text-end"
                                value={mesuTable[virtualRow.index].quotationWon.toLocaleString()}
                                onChange={e => {
                                  const newMesuTable = [...mesuTable];
                                  newMesuTable[virtualRow.index].quotationWon = +e.target.value
                                    .replace(/,/g, "")
                                    .replace(/[^0-9]/g, "");
                                  setMesuTable(newMesuTable);

                                  const newMesuModifyList = [...mesuModifyList];
                                  newMesuModifyList[virtualRow.index] = true;
                                  setMesuModifyList(newMesuModifyList);
                                }}
                              />
                            </td>

                            <td>
                              <select
                                className="form-select"
                                value={mesuTable[virtualRow.index].processCheck}
                                onChange={e => {
                                  const newMesuTable = [...mesuTable];
                                  newMesuTable[virtualRow.index].processCheck = e.target.value;
                                  setMesuTable(newMesuTable);

                                  const newMesuModifyList = [...mesuModifyList];
                                  newMesuModifyList[virtualRow.index] = true;
                                  setMesuModifyList(newMesuModifyList);
                                }}
                              >
                                {getOrderManageStatusList.data?.map(el => (
                                  <option value={el.key}>{el.value}</option>
                                ))}
                              </select>
                            </td>
                            <td>
                              <select
                                className="form-select"
                                value={mesuTable[virtualRow.index].completeKind}
                                onChange={e => {
                                  const newMesuTable = [...mesuTable];
                                  newMesuTable[virtualRow.index].completeKind = e.target.value;
                                  setMesuTable(newMesuTable);

                                  const newMesuModifyList = [...mesuModifyList];
                                  newMesuModifyList[virtualRow.index] = true;
                                  setMesuModifyList(newMesuModifyList);
                                }}
                              >
                                <option value="0">전체</option>
                                <option value="1">입금 완료된 매수</option>
                                <option value="2">입금 미완료 매수</option>
                                <option value="3">미선택</option>
                              </select>
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                value={mesuTable[virtualRow.index].remark}
                                onChange={e => {
                                  const newMesuTable = [...mesuTable];
                                  newMesuTable[virtualRow.index].remark = e.target.value;
                                  setMesuTable(newMesuTable);

                                  const newMesuModifyList = [...mesuModifyList];
                                  newMesuModifyList[virtualRow.index] = true;
                                  setMesuModifyList(newMesuModifyList);
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="date"
                                className="form-control"
                                value={mesuTable[virtualRow.index].requestDate}
                                onChange={e => {
                                  const newMesuTable = [...mesuTable];
                                  newMesuTable[virtualRow.index].requestDate = e.target.value;
                                  setMesuTable(newMesuTable);

                                  const newMesuModifyList = [...mesuModifyList];
                                  newMesuModifyList[virtualRow.index] = true;
                                  setMesuModifyList(newMesuModifyList);
                                }}
                                max="9999-12-31"
                              />
                            </td>
                            <td>{mesuTable[virtualRow.index].modDate}</td>
                            <td>{mesuTable[virtualRow.index].inputDate}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={13}>검색결과가 없습니다.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
