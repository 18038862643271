import React from "react";
import moment from "moment/moment";
import { useQueryClient } from "@tanstack/react-query";
import { regex_only_number } from "../../../../../../util/validation";

const TableRow = props => {
  const { data, setData, index } = props;
  const queryClient = useQueryClient();
  const jisaGubun = queryClient.getQueryData(["getJisaGubunAllQ1"]);
  const userIdQ = queryClient.getQueryData(["getUserIdQ"]);
  const memberNm = queryClient.getQueryData(["getMemberNm"]);
  const etcCmQ = queryClient.getQueryData(["getEtcCmQ"]);
  const etcSmQ = queryClient.getQueryData(["getEtcSmQ"]);
  const golfList2 = queryClient.getQueryData(["getGolfList2"]);

  const onChangeEvent = (key, value) => {
    setData(prev =>
      prev.map((i, idx) => {
        if (idx === index) {
          if (key === "gubun2") {
            return {
              ...i,
              [key]: value,
              golfId: "",
              edited: true,
              roomNo:
                value === "1" ? memberNm[0].code : value === "2" ? etcCmQ[0].key : etcSmQ[0].key,
            };
          } else if (key === "jisaGerae") {
            if (value === "S") {
              // 샵거래익령우 거래 분류 타샵물건으로 자동 변경
              return { ...i, [key]: value, golfId: "", tradGubn: "8", edited: true };
            } else {
              return { ...i, [key]: value, golfId: "", edited: true };
            }
          } else return { ...i, [key]: value, edited: true };
        } else return i;
      })
    );
  };

  const btn_delete = () => {
    setData(prev => prev.map((i, idx) => (idx === index ? { ...i, delete: true } : i)));
  };

  // 거래연번 표시
  const displaySeqNo = () => {
    if ((data.seqNo ?? "").length > 6)
      return data.seqNo.substring(0, 6) + "-" + data.seqNo.substring(6);
    else return data.seqNo;
  };

  const getSelectBox = () => {
    switch (data.gubun2) {
      case "1":
        return memberNm?.map(i => <option value={i.code}>{i.codename}</option>);
      case "2":
        return etcCmQ?.map(i => <option value={i.key}>{i.value}</option>);
      case "3":
        return etcSmQ?.map(i => <option value={i.key}>{i.value}</option>);
    }
  };

  return (
    <tr>
      <td>{index + 1}</td>
      <td>
        <select
          className="form-select"
          value={data.jisaGerae}
          onChange={e => onChangeEvent("jisaGerae", e.target.value)}
        >
          <option value="N">지사內</option>
          <option value="Y">타지사</option>
          <option value="S">샵거래</option>
        </select>
      </td>

      <td>
        <select
          className="form-select"
          value={data.saleGu}
          onChange={e => onChangeEvent("saleGu", e.target.value)}
        >
          <option value="1">실거래</option>
          <option value="2">분양</option>
          <option value="3">증여</option>
          <option value="4">상속</option>
          <option value="5">명의변경</option>
        </select>
      </td>
      <td>
        <input
          type="date"
          max={"9999-12-31"}
          className="form-control filter-input filter1-input2 w-110px"
          value={data?.custDate && moment(data.custDate).format("YYYY-MM-DD")}
          onChange={e => onChangeEvent("custDate", e.target.value)}
        />
      </td>
      <td>
        <select
          className="form-select"
          value={data.tradGubn}
          onChange={e => onChangeEvent("tradGubn", e.target.value)}
        >
          <option value="3">개인물건</option>
          <option value="4">법인물건</option>
          <option value="8">타샵물건</option>
        </select>
      </td>
      <td>
        <input
          type="text"
          className="form-control table-input"
          maxLength={10}
          value={displaySeqNo()}
          onChange={e => onChangeEvent("seqNo", e.target.value.replaceAll("-", ""))}
        />
      </td>
      <td>
        <select
          className="form-select"
          value={data.gubun1}
          onChange={e => onChangeEvent("gubun1", e.target.value)}
        >
          <option value="1">양도</option>
          <option value="2">양수</option>
        </select>
      </td>
      <td>
        <select
          className="form-select"
          value={data.gubun2}
          onChange={e => onChangeEvent("gubun2", e.target.value)}
        >
          <option value="1">골프</option>
          <option value="2">콘도</option>
          <option value="3">헬스</option>
        </select>
      </td>
      <td>
        <select
          className="form-select"
          value={data.golfId}
          onChange={e => onChangeEvent("golfId", e.target.value)}
          disabled={data.gubun2 !== "1"}
        >
          <option value={""}>미해당</option>
          {golfList2?.map(i => (
            <option value={i.key}>{i.value}</option>
          ))}
        </select>
      </td>
      <td>
        <select
          className="form-select"
          value={data.roomNo}
          onChange={e => onChangeEvent("roomNo", e.target.value)}
        >
          {getSelectBox()}
        </select>
      </td>
      <td>
        <input
          type="text"
          className="form-control table-input"
          value={data.memberNo}
          onChange={e => onChangeEvent("memberNo", e.target.value)}
        />
      </td>
      <td>
        <input
          type="text"
          className="form-control table-input"
          value={Number(data.yangdoWon2).toLocaleString("en-US")}
          onChange={e =>
            regex_only_number.test(e.target.value.replaceAll(",", ""))
              ? onChangeEvent("yangdoWon2", e.target.value.replaceAll(",", ""))
              : false
          }
        />
      </td>
      <td>
        <input
          type="text"
          className="form-control table-input"
          value={Number(data.yangdoWon1).toLocaleString("en-US")}
          onChange={e =>
            regex_only_number.test(e.target.value.replaceAll(",", ""))
              ? onChangeEvent("yangdoWon1", e.target.value.replaceAll(",", ""))
              : false
          }
        />
      </td>

      <td>
        <select
          className="form-select"
          value={data.downYn}
          onChange={e => onChangeEvent("downYn", e.target.value)}
        >
          <option value="Y">인증</option>
          <option value="N">인정안됨</option>
          <option value="D">해당無</option>
        </select>
      </td>
      <td>
        <input
          type="text"
          className="form-control table-input"
          value={Number(data.commission).toLocaleString("en-US")}
          onChange={e =>
            regex_only_number.test(e.target.value.replaceAll(",", ""))
              ? onChangeEvent("commission", e.target.value.replaceAll(",", ""))
              : false
          }
        />
      </td>
      <td>
        <select
          className="form-select"
          value={data.differCheck}
          onChange={e => onChangeEvent("differCheck", e.target.value)}
        >
          <option value="Y">Y</option>
          <option value="N">N</option>
        </select>
      </td>
      <td>
        <input
          type="text"
          className="form-control table-input"
          value={data.remark}
          onChange={e => onChangeEvent("remark", e.target.value)}
        />
      </td>
      <td>
        <select
          className="form-select"
          value={data.partner}
          onChange={e => onChangeEvent("partner", e.target.value)}
        >
          {userIdQ?.map(item => (
            <option value={item.key}>{item.value}</option>
          ))}
        </select>
      </td>
      <td>
        <select
          className="form-select"
          value={data.jisa}
          onChange={e => onChangeEvent("jisa", e.target.value)}
        >
          {jisaGubun?.map(item => (
            <option value={item.key}>{item.value}</option>
          ))}
        </select>
      </td>
      <td>
        <select
          className="form-select"
          value={data.taxTarget}
          onChange={e => onChangeEvent("taxTarget", e.target.value)}
        >
          <option value="Y">대상</option>
          <option value="N">비대상</option>
          <option value="R">관청접수</option>
          <option value="S">완료</option>
        </select>
      </td>
      <td>
        <select
          className="form-select"
          value={data.yangdoTaxTarget}
          onChange={e => onChangeEvent("yangdoTaxTarget", e.target.value)}
        >
          <option value="Y">대상</option>
          <option value="N">비대상</option>
          <option value="H">발송대기</option>
          <option value="S">완료</option>
        </select>
      </td>
      <td>{userIdQ?.find(item => item.key === data?.userid)?.value}</td>
      <td>{moment(data.modDate).format("YYYY-MM-DD HH:mm:ss")}</td>
      <td className="text-center">
        <a href={void 0}>
          <img
            name={"deleteImg"}
            src={process.env.PUBLIC_URL + "/images/delete.png"}
            alt=""
            onClick={btn_delete}
          />
        </a>
      </td>
    </tr>
  );
};

export default React.memo(TableRow);
