import { atom } from "jotai";

export const customerManage_searchDataInit_atom = {
  again: false,
  asCode: "",
  includeEx: true,
  includeSms: null,
  keyword: "",
  mngByMe: false,
  mngPlace: false,
  page: 1,
  pastOwner: null,
  radio: null,
  searchTypeSwitch: "",
  seqNo: "",
  selectOption: null,
  isRankSelectActive: null,
  rankSelect: null,
  gubun: null,
  searchType: "1", // 1이면 일반 검색, 2이면 트리 검색, 3이면 상세 검색
};

export const customerManage_searchData_atom = atom(customerManage_searchDataInit_atom);

export const customerManage_selectedCustomer_atom = atom("0");

export const customerManage_searchedTableListInit_atom = {
  list: [],
  tree: [],
  total: 0,
  exchangeA: 0,
  exchangeB: 0,
  exchangeMineCnt: 0,
};

export const customerManage_searchedTableList_atom = atom(
  customerManage_searchedTableListInit_atom
);

export const customerManage_searchedTableListChecked_atom = atom([]);

export const customerManage_tableLimit_atom = atom("5");

export const customerManage_tapList_atom = atom([]);

export const customerManage_mainDataInit_atom = {
  accessDeny: "",
  allFlag: "",
  authRemark: "",
  bigoRemk: "",
  birthDate: "",
  caddres: "",
  cellular: "",
  cfax: "",
  changeDate: "",
  chkFlag: "",
  codename: "",
  compCust: "",
  compJuso: "",
  company: "",
  corpCheck: "",
  ctel: "",
  ctelChange: "",
  custGroup: "",
  custId: "",
  czipcode: "",
  dagolfMember: "",
  dbBorn: "",
  dbCode: "",
  dbReal: "",
  devision: "",
  donotcallChk: "",
  egubun: "99",
  email: "",
  etccode: {},
  exchange02: {},
  exchangeCnt: 0,
  exchangeDate: "",
  exchangeFlag: "",
  exchangeMine: "",
  faxCheck: "",
  faxNDate: "",
  flag: "",
  gubun: "",
  haddres: "",
  hpCheck: "Y",
  hpCheck2: "Y",
  htel: "",
  hzipcode: "",
  ilNo: "",
  inDate: "",
  inputDate: "",
  inputId: "",
  jisa: "",
  jisaProtect: "",
  jobPermit: "",
  juminno: "",
  kindGubn: "",
  kname: "",
  kosdaqCheck: "",
  mainCellular: "",
  memberAddress: "",
  memberJuminno: "",
  memberKname: "",
  modDate: "",
  modId: "",
  modeDate: "",
  moveHope: "",
  oldPartner: "",
  opposite: {},
  outCheck: "N",
  outDate: "",
  outModDate: "",
  outModUserid: "",
  ownerCTel: "",
  ownerCelTel: "",
  ownerDevision: "",
  ownerEGubun: "99",
  ownerEMail: "",
  ownerList: [],
  ownerName: "",
  ownerPosition: "",
  ownerRelation: "",
  ownerRemark: "",
  partner: "",
  payAccount: "",
  payBank: "",
  payName: "",
  position: "",
  postCheck: "",
  procDate: "",
  procGubn: "",
  procId: "",
  rcAddres: "",
  rcAddresTxt: "",
  rcAreaCode: "",
  rcBdcode: "",
  rcZipcode: "",
  remark: "",
  rhAddres: "",
  rhAddresTxt: "",
  rhAreaCode: "",
  rhBdcode: "",
  rhZipcode: "",
  roadZipcode: {},
  saupno: "",
  seqNo: "",
  sequenNo: 0,
  shopCust: "",
  siteUrl: "",
  telCheck: "N",
  tipsCheck: "",
  upteCode: "9999",
  userId: "",
  userName: "",
  userid: "",
  viewFlag: "",
  vipCheck: "",
  zippJuso: "",
  reason: "",
};

export const customerManage_mainData_atom = atom(customerManage_mainDataInit_atom);

export const customerManage_subData_atom = atom([]);

export const customerManage_subDataModifyList_atom = atom([]);

// export const customerManage_changeHistory_atom = atom([]);

export const customerManage_isDuplicateCheck_atom = atom(false);

export const weborderList_atom = atom([]);
